import React, { useEffect, useState } from 'react';
import AppBar from '../components/AppBar';
import AppBarNone from '../components/AppBarNone';
import Loading from '../components/Loading';
import '../App.css';
import '../css/awesomplete.css';
import muiStyles from './muiStyle';
import useLiff from '../hooks/useAuth';
import axios from 'axios';
import { API, isDealer } from '../hooks/api';
import clsx from 'clsx';
import { availableMonths, humanExpireTime, encode, getDateList, getYearList, zeroPadding } from './helpper';
import moment from 'moment';

import Oct21Discount75Img from '../assets/oct21/discount75.jpg';
import Oct21Discount175Img from '../assets/oct21/discount175.jpg';
import VoucherGTS1Img from '../assets/feb22/voucherGTS1.jpg';
import VoucherGTS2Img from '../assets/feb22/voucherGTS2.jpg';
import VoucherGTS3Img from '../assets/feb22/voucherGTS3.jpg';
import VoucherGTS4Img from '../assets/feb22/voucherGTS4.jpg';
import VoucherGTS5Img from '../assets/feb22/voucherGTS5.jpg';
import Plus1Img from '../assets/jan22/plus1.jpg';
import Plus21Img from '../assets/jan22/plus21.jpg';
import Plus22Img from '../assets/jan22/plus22.jpg';
import Free21Img from '../assets/oct22/free2_1.jpg';
// import Free23Img from '../assets/may22/free2_3.jpg';
import SGTS1Img from '../assets/oct22/s_gts1.jpg';
import SGTS2Img from '../assets/oct22/s_gts2.jpg';
import SGTS3Img from '../assets/oct22/s_gts3.jpg';
import SGTS5Img from '../assets/may22/s_gts5.jpg';
import SGTS6Img from '../assets/may22/s_gts6.jpg';
import SGTS7Img from '../assets/may22/s_gts7.jpg';
import CC1501Img from '../assets/jun22/150cc1.jpg';
import CC1502Img from '../assets/jun22/150cc2.jpg';
import MATImg from '../assets/apr22/mat.jpg';
import ACC1Img from '../assets/oct22/acc1.jpg';
import ACC2Img from '../assets/oct22/acc2.jpg';
import ACC3Img from '../assets/jul22/acc3.jpg';
import ACC4Img from '../assets/jul22/acc4.jpg';
import ACC5Img from '../assets/jul22/acc5.jpg';
import AW1 from '../assets/oct22/AW1.jpg';
import AW2 from '../assets/oct22/AW2.jpg';
import AW3 from '../assets/oct22/AW3.jpg';
import AW4 from '../assets/oct22/AW4.jpg';
import AW1OCT22 from '../assets/oct22/AW1OCT22.jpg';
import AW2OCT22 from '../assets/oct22/AW2OCT22.jpg';
import C01AW1NOV65 from '../assets/nov22/01AW1NOV65.jpg'
import C03AW1NOV65 from '../assets/nov22/03AW1NOV65.jpg'
import C03AW2NOV65 from '../assets/nov22/03AW2NOV65.jpg'
import C03AW3NOV65 from '../assets/nov22/03AW3NOV65.jpg'
import C03AW4NOV65 from '../assets/nov22/03AW4NOV65.jpg'
import C04AW1NOV65 from '../assets/nov22/04AW1NOV65.jpg'
import C04AW2NOV65 from '../assets/nov22/04AW2NOV65.jpg'
import C04AW3NOV65 from '../assets/nov22/04AW3NOV65.jpg'
import C05AW1NOV65 from '../assets/nov22/05AW1NOV65.jpg'
import C06AW1NOV65 from '../assets/nov22/06AW1NOV65.jpg'
import C11AW1NOV65 from '../assets/nov22/11AW1NOV65.jpg'
import C11AW2NOV65 from '../assets/nov22/11AW2NOV65.jpg'
import C11AW3NOV65 from '../assets/nov22/11AW3NOV65.jpg'
import C11AW4NOV65 from '../assets/nov22/11AW4NOV65.jpg'
import C11AW5NOV65 from '../assets/nov22/11AW5NOV65.jpg'
import C09AW1DEC65 from '../assets/Dec22/09AW1DEC65.jpg'
import C09AW2DEC65 from '../assets/Dec22/09AW2DEC65.jpg'
import C11AW1DEC65 from '../assets/Dec22/11AW1DEC65.jpg'
import C12AW1DEC65 from '../assets/Dec22/12AW1DEC65.jpg'

import C01AW1JAN66 from '../assets/Jan23/01AW1JAN66.jpg'
import C01AW2JAN66 from '../assets/Jan23/01AW2JAN66.jpg'
import C02AW1JAN66 from '../assets/Jan23/02AW1JAN66.jpg'
import C02AW2JAN66 from '../assets/Jan23/02AW2JAN66.jpg'
import C09AW1JAN66 from '../assets/Jan23/09AW1JAN66.jpg'
import C09AW2JAN66 from '../assets/Jan23/09AW2JAN66.jpg'
import C09AW3JAN66 from '../assets/Jan23/09AW3JAN66.jpg'

import C01AW1FEB66 from '../assets/Feb23/01AW1FEB66.jpg'
import C01AW2FEB66 from '../assets/Feb23/01AW2FEB66.jpg'
import C02AW1FEB66 from '../assets/Feb23/02AW1FEB66.jpg'
import C02AW2FEB66 from '../assets/Feb23/02AW2FEB66.jpg'
import C02AW3FEB66 from '../assets/Feb23/02AW3FEB66.jpg'
import C03AW1FEB66 from '../assets/Feb23/03AW1FEB66.jpg'
import C03AW2FEB66 from '../assets/Feb23/03AW2FEB66.jpg'
import C04AW1FEB66 from '../assets/Feb23/04AW1FEB66.jpg'
import C04AW2FEB66 from '../assets/Feb23/04AW2FEB66.jpg'
import C09AW1FEB66 from '../assets/Feb23/09AW1FEB66.jpg'
import C09AW2FEB66 from '../assets/Feb23/09AW2FEB66.jpg'
import C10AW1FEB66 from '../assets/Feb23/10AW1FEB66.jpg'
import C10AW2FEB66 from '../assets/Feb23/10AW2FEB66.jpg'
import C10AW3FEB66 from '../assets/Feb23/10AW3FEB66.jpg'
import C11AW1FEB66 from '../assets/Feb23/11AW1FEB66.jpg'
import C11AW2FEB66 from '../assets/Feb23/11AW2FEB66.jpg'
import C13AW1FEB66 from '../assets/Feb23/13AW1FEB66.jpg'
import C13AW2FEB66 from '../assets/Feb23/13AW2FEB66.jpg'

import C04AW1MAR66 from '../assets/Mar23/04AW1MAR66.jpg'
import C04AW2MAR66 from '../assets/Mar23/04AW2MAR66.jpg'
import C05AW1MAR66 from '../assets/Mar23/05AW1MAR66.jpg'
import C05AW2MAR66 from '../assets/Mar23/05AW2MAR66.jpg'
import C08AW1MAR66 from '../assets/Mar23/08AW1MAR66.jpg'
import C08AW2MAR66 from '../assets/Mar23/08AW2MAR66.jpg'

import C04AW1APR66 from '../assets/April23/04AW1APR66.jpg'
import C04AW2APR66 from '../assets/April23/04AW2APR66.jpg'
import C04AW3APR66 from '../assets/April23/04AW3APR66.jpg'
import C05AW1APR66 from '../assets/April23/05AW1APR66.jpg'
import C05AW2APR66 from '../assets/April23/05AW2APR66.jpg'
import C05AW3APR66 from '../assets/April23/05AW3APR66.jpg'
import C06AW1APR66 from '../assets/April23/06AW1APR66.jpg'
import C07AW1APR66 from '../assets/April23/07AW1APR66.jpg'
import C08AW1APR66 from '../assets/April23/08AW1APR66.jpg'

import C01AW1MAY66 from '../assets/May23/01AW1MAY66.jpg'
import C01AW2MAY66 from '../assets/May23/01AW2MAY66.jpg'
import C08AW1MAY66 from '../assets/May23/08AW1MAY66.jpg'


import C01AW1JUL66 from '../assets/Jul23/01AW1JUL66.jpg'
import C01AW2JUL66 from '../assets/Jul23/01AW2JUL66.jpg'
import C01AW3JUL66 from '../assets/Jul23/01AW3JUL66.jpg'
import C02AW1JUL66 from '../assets/Jul23/02AW1JUL66.jpg'
import C02AW2JUL66 from '../assets/Jul23/02AW2JUL66.jpg'
import C03AW1JUL66 from '../assets/Jul23/03AW1-2JUL66.jpg'
import C03AW3JUL66 from '../assets/Jul23/03AW3JUL66.jpg'
import C04AW1JUL66 from '../assets/Jul23/04AW1JUL66.jpg'
import C04AW2JUL66 from '../assets/Jul23/04AW2JUL66.jpg'
import C04AW3JUL66 from '../assets/Jul23/04AW3JUL66.jpg'
import C04AW4JUL66 from '../assets/Jul23/04AW4JUL66.jpg'
// import C04AW5JUL66 from '../assets/Jul23/04AW5JUL66.jpg'
import C05AW1JUL66 from '../assets/Jul23/05AW1JUL66.jpg'
import C05AW2JUL66 from '../assets/Jul23/05AW2JUL66.jpg'
import C05AW3JUL66 from '../assets/Jul23/05AW3JUL66.jpg'
import C06AW1JUL66 from '../assets/Jul23/06AW1JUL66.jpg'
import C06AW2JUL66 from '../assets/Jul23/06AW2JUL66.jpg'
import C06AW3JUL66 from '../assets/Jul23/06AW3JUL66.jpg'
import C07AW1JUL66 from '../assets/Jul23/07AW1JUL66.jpg'
import C08AW1JUL66 from '../assets/Jul23/08AW1JUL66.jpg'

import C01AW1AUG66 from '../assets/Aug23/01AW1AUG66.jpg'
import C01AW2AUG66 from '../assets/Aug23/01AW2AUG66.jpg'
import C01AW3AUG66 from '../assets/Aug23/01AW3AUG66.jpg'
import C02AW12AUG66 from '../assets/Aug23/02AW1-2AUG66.jpg'
import C03AW12AUG66 from '../assets/Aug23/03AW1-2AUG66.jpg'
import C03AW3AUG66 from '../assets/Aug23/03AW3AUG66.jpg'
import C04AW14AUG66 from '../assets/Aug23/04AW1-4AUG66.jpg'

import C05AW1AUG66 from '../assets/Aug23/05AW1AUG66.jpg'
import C05AW2AUG66 from '../assets/Aug23/05AW2AUG66.jpg'
import C05AW3AUG66 from '../assets/Aug23/05AW3AUG66.jpg'
import C05AW4AUG66 from '../assets/Aug23/05AW4AUG66.jpg'
import C06AW1AUG66 from '../assets/Aug23/06AW1AUG66.jpg'
import C07AW1AUG66 from '../assets/Aug23/07AW1AUG66.jpg'
import C07AW2AUG66 from '../assets/Aug23/07AW2AUG66.jpg'
import C08AW1AUG66 from '../assets/Aug23/08AW1AUG66.jpg'
import C08AW2AUG66 from '../assets/Aug23/08AW2AUG66.jpg'

import C01AW1SEP66 from '../assets/Sep23/01AW1SEP66.jpg'
import C01AW2SEP66 from '../assets/Sep23/01AW2SEP66.jpg'
import C01AW3SEP66 from '../assets/Sep23/01AW3SEP66.jpg'

import C02AW1SEP66 from '../assets/Sep23/02AW1SEP66.jpg'
import C02AW12SEP66 from '../assets/Sep23/02AW1-2SEP66.jpg'
import C02AW2SEP66 from '../assets/Sep23/02AW2SEP66.jpg'
import C02AW3SEP66 from '../assets/Sep23/02AW3SEP66.jpg'
import C02AW4SEP66 from '../assets/Sep23/02AW4SEP66.jpg'

import C03AW1SEP66 from '../assets/Sep23/03AW1SEP66.jpg'

import C04AW1SEP66 from '../assets/Sep23/04AW1SEP66.jpg'
import C04AW2SEP66 from '../assets/Sep23/04AW2SEP66.jpg'
import C04AW3SEP66 from '../assets/Sep23/04AW3SEP66.jpg'

import C05AW1SEP66 from '../assets/Sep23/05AW1SEP66.jpg'
import C05AW2SEP66 from '../assets/Sep23/05AW2SEP66.jpg'

import C06AW1SEP66 from '../assets/Sep23/06AW1SEP66.jpg'
import C06AW2SEP66 from '../assets/Sep23/06AW2SEP66.jpg'
import C06AW3SEP66 from '../assets/Sep23/06AW3SEP66.jpg'
import C06AW4SEP66 from '../assets/Sep23/06AW4SEP66.jpg'

import C07AW1SEP66 from '../assets/Sep23/07AW1SEP66.jpg'
import C07AW2SEP66 from '../assets/Sep23/07AW2SEP66.jpg'
import C07AW3SEP66 from '../assets/Sep23/07AW3SEP66.jpg'

import C01AW1OCT66 from '../assets/Oct23/01AW1OCT66.jpg'
import C02AW1OCT66 from '../assets/Oct23/02AW1OCT66.jpg'

import M1 from '../assets/Mar24/01MAR.jpg'
import M2 from '../assets/Mar24/02MAR.jpg'
import M3 from '../assets/Mar24/03MAR.jpg'
import M4 from '../assets/Mar24/04MAR.jpg'
import M5 from '../assets/Mar24/05MAR.jpg'


import J5 from '../assets/Jan24/J5.jpg'
import J6 from '../assets/Jan24/J6.jpg'

import F1 from '../assets/Feb24/feb01.jpg'
import F2 from '../assets/Feb24/feb02.jpg'
import F3 from '../assets/Feb24/feb03.jpg'


import { useParams } from 'react-router-dom';

// october 2021 : 1:ft26 , 2:ft26, 3:ft5, 4:ft25, 5:ft27 old1:ft10 , old2:ft19 

const init = {
    userInfo: null,
    firstname: "",  // ชื่อ
    lastname: "", // นามสกุล
    mobile: "", // เบอร์โทรศัพท์
    province: "",
    district: "",
    tumbon: "",
    postCode: "",
    addressDetail: "", // add
    reservationCode: "",
    model: "", // รุ่นรถ
    size: "", // ขนาดเครื่องยนต์
    product: "", // สินค้าที่ซื้อ
    manualSelectedModel: "",
    engineNumber: "", // เลขเครื่องยนต์
    bodyNumber: "", // เลขถังรถ
    purchasedDate: "",
    purchasedMonth: "",
    purchasedYear: 2025,

    serviceDate: "", // วันที่ใช้บริการ
    serviceMonth: "",
    serviceYear: 2025,

    couponValue: "", // มูลค่าคูปอง
    purchasedAmount: "",

    usageAmount: "", // มูลค่าที่ซื้อสินค้า
    serviceUsage: "",
    mileageCheckup: "",
    reward: "",

    additionalCost: "",

    couponDetail: "",
    couponTerm: [],
    branchList: [],
    targetBranch: "", // โชว์รูม/ศูนย์บริการ
    targetBranchId: "",

    sparepart: "", // อะไหล่
    expenses: "", // ค่าใช้จ่าย
    color: "",
    package: "",
    changedItems: "",

    isInputDirty: false,
    invalidBranch: false,
    isOverlayActive: false,
}

const MyNewCouponDetail = () => {
    const { id } = useParams()
    const classes = muiStyles();
    const [loading, setLoading] = useState(false);
    const [couponCode, setCouponCode] = useState();
    const [image, setImage] = useState();
    const [engineNumber, setEngineNumber] = useState();
    const [showVoucher, setShowVoucher] = useState(false);
    const [voucherSet, setVoucherSet] = useState();

    const [isShow, setShow] = useState(false);

    const [data, setData] = useState({});
    const [termList, setTermList] = useState([]);

    const { getProfile, getAccessToken } = useLiff();

    const [formData, setFormData] = useState({ ...init });
    const [formBlur, setFormBlur] = useState({});
    const [formType, setFormType] = useState();
    const [rewardType, setRewardType] = useState();
    const [isSubmit, setIsSubmit] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [notValidServiceDate, setNotValidServiceDate] = useState(false);
    const [notValidPurchaseDate, setNotValidPurchaseDate] = useState(false);

    const [modelList, setModelList] = useState([]); // รุ่นรถ
    const [branchList, setBranchList] = useState([]); // สาขา
    const [branchListAll, setBranchListAll] = useState([]); // สาขา
    const [modelListAll, setModelListAll] = useState([]);
    const [sizeList, setSizeList] = useState([]); // ขนาดเครื่องยนต์
    const [usegeList, setUsageList] = useState([]); // มูลค่าที่ซื้อสินค้า
    const [dateList, setDateList] = useState([]); // วัน
    const [monthList, setMonthList] = useState([]); // เดือน
    const [yearList, setYearList] = useState([]); // ปี
    const [mileagList, setMileagList] = useState([]); // ตรวจเช็คระยะ
    const [sparepartList, setSparepartList] = useState([]); // อะไหล่
    const [expenses, setExpenses] = useState([]); // อะไหล่
    var month = moment().month()
    const daysInMonth = moment().daysInMonth()

    const token = getAccessToken
    const profile = getProfile

    const queryParams = new URLSearchParams(window.location.search);
    const branchId = queryParams.get('branchId');

    useEffect(() => {
        (async () => {
            if (!token) return
            if (!profile?.userId) return
            await getCheckProfile();
        })();
    }, [token, profile]);

    const getCheckProfile = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.CHECK_PROFILE, encodedData);
        if (response?.data?.detail === 'profile_existing') {
            setShow(true);
            (async () => {
                setShow(true);
                await initInfo();
                await initMaster();
            })();
        }
        else {
            window.location = `/?branchId=${branchId}`
        }
    }

    const initInfo = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.MY_INFO, encodedData);
        const info = response?.data?.myinfo[0];
        setFormData({
            ...init,
            firstname: info?.firstname,
            lastname: info?.lastname,
            mobile: info?.mobile,
            bodyNumber: info?.chassisnumber
            // engineNumber: info?.enginenumber
        })
        setEngineNumber(info?.enginenumber || '');
    }

    const initMaster = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
            ...(isDealer && { dealerid: branchId })
        }

        const encodedData = encode(param);
        const coupons = await axios.post(API.LOAD_MY_COUPON, encodedData);
        const items = coupons?.data?.items || [];
        const find = items.find(f => f.itemid === id);

        setData(find);
    }

    useEffect(() => {
        setCouponCode(data?.code);
        setTermList((data?.termth || '').split('!!'));
    }, [data])

    /*** ---------------------- master dropdown ---------------------- ***/

    useEffect(() => {
        const type = data?.type;
        console.log(type);
        var formType = '';
        // if (type === 'formType20') {
        //     formType = 'formType1';
        // }
        if (type === 'RewardFillAddress2') {
            formType = 'formType2';
        }
        // else if (type === '') {
        //     formType = 'formType3';
        // }
        else if (type === 'RewardCouponWithCond4' || type === 'RewardCouponWithCond4C') {
            formType = 'formType4';
        }
        else if (type === 'RewardCouponWithCond5' || type === 'RewardCouponWithCond5C' || type === 'RewardCouponWithCond5B' || type === 'RewardCouponWithCond5D' || type === 'RewardFillAddress103'
            || type === 'RewardFillAddress155' || type === 'RewardFillAddress166' || type === 'RewardFillAddress182' || type === 'RewardFillAddress189'
            || type === 'RewardFillAddress194' || type === 'RewardFillAddress203' || type === 'RewardFillAddress208' || type === 'RewardFillAddress216' || type === 'RewardFillAddress221') {
            formType = 'formType5';
        }
        else if (type === 'RewardFillAddress11') {
            formType = 'formType6';
        }
        else if (type === 'RewardFillAddress12') {
            formType = 'formType7';
        }
        else if (type === 'RewardFillAddress13') {
            formType = 'formType8';
        }
        else if (type === 'RewardCouponWithCond6') {
            formType = 'formType9';
        }
        else if (type === 'RewardFillAddress253' || type === "RewardFillAddress254" || type === 'RewardFillAddress265') {
            formType = 'formType10';
        }
        else if (type === 'RewardFillAddress27' || type === 'RewardFillAddress27C') {
            formType = 'formType11';
        }
        else if (type === 'RewardFillAddress28') {
            formType = 'formType12';
        }
        else if (type === 'Reward3Get1May2021-1') {
            formType = 'formType13';
        }
        else if (type === 'RewardFillAddress31') {
            formType = 'formType14';
        }
        else if (type === 'RewardFillAddress32') {
            formType = 'formType15';
        }
        else if (type === 'RewardCouponWithCond4A') {
            formType = 'formType16';
            // if (this.userInfo.enginenumber !== "") {
            //     this.engineNumber = this.userInfo.enginenumber;
            //     this.engineNumberInputHandler()
            // }
        }
        else if (type === 'RewardFillAddress33') {
            formType = 'formType17';
        }
        else if (type === 'RewardFillAddress34') {
            formType = 'formType18';
        }
        else if (type === 'RewardFillAddress35') {
            formType = 'formType19';
        }
        else if (type === 'RewardFillAddress36' || type === 'RewardFillAddress41' || type === 'RewardFillAddress44' || type === 'RewardFillAddress198') {
            formType = 'formType20';
        }
        else if (type === 'RewardFillAddress38') {
            formType = 'formType21';
        }
        // else if (type === '') {
        //     formType = 'formType22';
        // }
        else if (type === 'RewardCouponWithCond7B') {
            formType = 'formType23';
        }
        else if (type === 'RewardFillAddress42' || type === 'RewardFillAddress45') {
            formType = 'formType24';
        }
        else if (type === 'RewardFillAddress46') {
            formType = 'formType25';
        }
        else if (type === 'RewardFillAddress47' || type === 'RewardFillAddress48'
            || type === 'RewardFillAddress157' || type === 'RewardFillAddress91'
            || type === 'RewardFillAddress191' || type === 'RewardFillAddress192'
            || type === 'RewardFillAddress193' || type === 'RewardFillAddress199'
            || type === 'RewardFillAddress200' || type === 'RewardFillAddress201'
            || type === 'RewardFillAddress202' || type === 'RewardFillAddress204'
            || type === 'RewardFillAddress205' || type === 'RewardFillAddress206'
            || type === 'RewardFillAddress207' || type === 'RewardFillAddress243'
            || type === 'RewardFillAddress213' || type === 'RewardFillAddress214'
            || type === 'RewardFillAddress215' || type === 'RewardFillAddress217'
            || type === 'RewardFillAddress218' || type === 'RewardFillAddress228'
            || type === 'RewardFillAddress220' || type === 'RewardFillAddress226'
            || type === 'RewardFillAddress244' || type === 'RewardFillAddress228'
            || type === 'RewardFillAddress229' || type === 'RewardFillAddress235'
            || type === 'RewardFillAddress236' || type === 'RewardFillAddress245'
            || type === 'RewardFillAddress246' || type === 'RewardFillAddress249'
            || type === 'RewardFillAddress250' || type === 'RewardFillAddress251'
            || type === 'RewardFillAddress252' || type === 'RewardFillAddress257'
            || type === 'RewardFillAddress258' || type === 'RewardFillAddress259'
            || type === 'RewardFillAddress260' || type === 'RewardFillAddress261'
            || type === 'RewardFillAddress262' || type === 'RewardFillAddress263'
            || type === 'RewardFillAddress264' || type === 'RewardFillAddress266'
            || type === 'RewardFillAddress267' || type === 'RewardFillAddress268'
            || type === 'RewardFillAddress269' || type === 'RewardFillAddress270'
            || type === 'RewardFillAddress271' || type === 'RewardFillAddress272'
            || type === 'RewardFillAddress273' || type === 'RewardFillAddress276'
            || type === 'RewardFillAddress277' || type === 'RewardFillAddress278'
            || type === 'RewardFillAddress279' || type === 'RewardFillAddress280'
            || type === 'RewardFillAddress301' || type === 'RewardFillAddress302'
            || type === 'RewardFillAddress303' || type === 'RewardFillAddress300'

        ) {
            formType = 'formType26';
        }
        else if (type === 'RewardFillAddress274' || type === 'RewardFillAddress275' || type === 'RewardFillAddress247' || type === 'RewardFillAddress248') {
            formType = 'formType27';
        }
        else if (type === 'RewardFillAddress50' || type === 'RewardFillAddress51') {
            formType = 'formType28';
        }
        else if (
            type === 'RewardFillAddress93' || type === 'RewardFillAddress94'
            || type === 'RewardFillAddress98' || type === 'RewardFillAddress99'
            || type === 'RewardFillAddress146' || type === 'RewardFillAddress113'
            || type === 'RewardFillAddress160' || type === 'RewardFillAddress161'
            || type === 'RewardFillAddress171' || type === 'RewardFillAddress172'
            || type === 'RewardFillAddress183' || type === 'RewardFillAddress184'
        ) {
            formType = 'formType30';
        }
        else if (type === 'RewardFillAddress80' || type === 'RewardFillAddress81') {
            formType = 'formType29';
            setFormData({
                ...formData,
                model: 'LX 125 CC'
            })

            if (type === 'RewardFillAddress80') {
                setShowVoucher(<>
                    &bull; Premium Set (หมวกกันน็อค, หน้ากากผ้า, ผ้าพันคอ, ผ้าคลุมเบาะ, พวงกุญแจ, กระเป๋าผ้า และชุดสติกเกอร์)
                </>);
            }
            if (type === 'RewardFillAddress81') {
                setShowVoucher(<>
                    &bull; Premium Mini Set (กระเป๋าผ้า และพวงกุญแจ)
                </>);
            }
        } else if (
            type === 'RewardFillAddress139' || type === 'RewardFillAddress173'
            || type === 'RewardFillAddress195' || type === 'RewardFillAddress222' || type === 'RewardFillAddress239'
        ) {
            formType = 'formType31';
        } else if (type === 'RewardFillAddress223' || type === 'RewardFillAddress211') {
            formType = 'formType32';
        } else if (type === 'RewardFillAddress234') {
            formType = 'formType33';
        } else if (type === 'RewardFillAddress284') {
            formType = 'formType34';
        } else if (type === 'RewardFillAddress285' || type === 'RewardFillAddress298') {
            formType = 'formType35';
        } else if (type === 'RewardFillAddress286' || type === 'RewardFillAddress299') {
            formType = 'formType36';
        } else if (type === 'RewardFillAddress291') {
            formType = 'formType37';
        } else if (type === 'RewardFillAddress296' || type === 'RewardFillAddress297') {
            formType = 'formType38';
        }

        setFormType(formType);
        setRewardType(type);
    }, [data])

    useEffect(() => {
        if (formType) {
            if (
                formType === 'formType5'
                || formType === 'formType20'
                || formType === 'formType21'
                || formType === 'formType23'
                || formType === 'formType24'
                || formType === 'formType25'
                || formType === 'formType26'
                || formType === 'formType27'
                || formType === 'formType28'
                || formType === 'formType10'
                || formType === 'formType19'
                || formType === 'formType29'
                || formType === 'formType30'
                || formType === 'formType31'
                || formType === 'formType32'
                || formType === 'formType33'
                || formType === 'formType34'
                || formType === 'formType35'
                || formType === 'formType36'
                || formType === 'formType37'
                || formType === 'formType38'
            ) {
                (async () => {
                    await masterModel();
                })();
            }

            if (
                formType === 'formType5'
                || formType === 'formType20'
                || formType === 'formType21'
                || formType === 'formType23'
                || formType === 'formType24'
                || formType === 'formType25'
                || formType === 'formType26'
                || formType === 'formType27'
                || formType === 'formType28'
                || formType === 'formType10'
                || formType === 'formType19'
                || formType === 'formType29'
                || formType === 'formType30'
                || formType === 'formType31'
                || formType === 'formType32'
                || formType === 'formType33'
                || formType === 'formType34'
                || formType === 'formType35'
                || formType === 'formType36'
                || formType === 'formType37'
                || formType === 'formType38'
            ) {
                (async () => {
                    await masterBranch();
                })();
            }

            if (
                formType === 'formType20'
                || formType === 'formType24'
                || formType === 'formType36'
            ) {
                setUsageAmount();
            }

            if (formType === 'formType25') {
                setMileag();
            }

            if (formType === 'formType27' || formType === 'formType32') {
                setSparepart();
            }

            if (
                formType === 'formType21'
                || formType === 'formType23'
                || formType === 'formType24'
                || formType === 'formType20'
                || formType === 'formType36'
            ) {
                setDateList(getDateList(1, daysInMonth));
                setMonthList([{ ...availableMonths[month] }]);
                setYearList([{ th: 2567, en: 2025 }]);
            }

            if (
                formType === 'formType5'
                || formType === 'formType25'
                || formType === 'formType27'
                || formType === 'formType28'
                || formType === 'formType32'
            ) {
                setDateList(getDateList(1, 31));
                setMonthList([{ ...availableMonths[11] }]);
                setYearList([{ th: 2566, en: 2023 }]);
            }

            // if (formType === 'formType26') {
            //     if (["RewardFillAddress178", "RewardFillAddress179", "RewardFillAddress180", "RewardFillAddress181", "RewardFillAddress182"].includes(data?.type)) {
            //         const startDate = formData.purchasedMonth === "11" ? 25 : 1
            //         setDateList(getDateList(startDate, formData.purchasedMonth % 2 === 0 ? 31 : 30));
            //         setMonthList([{ ...availableMonths[10] }, availableMonths[11]]);
            //         setYearList([{ th: 2565, en: 2022 }]);
            //     } else {
            //         setDateList(getDateList(1, 31));
            //         setMonthList([{ ...availableMonths[10] }]);
            //         setYearList([{ th: 2565, en: 2022 }]);
            //     }
            // }
            if (formType === 'formType31' || formType === 'formType33' || formType === 'formType34' || formType === 'formType35' || formType === 'formType37' || formType === 'formType38') {
                setDateList(getDateList(1, daysInMonth));
                setMonthList([{ ...availableMonths[month] }]);
                setYearList([{ th: 2567, en: 2025 }]);
            }

            // if (formType === 'formType5') {
            //     if (["RewardFillAddress178", "RewardFillAddress179", "RewardFillAddress180", "RewardFillAddress181", "RewardFillAddress182"].includes(data?.type)) {
            //         const startDate = formData.purchasedMonth === "11" ? 25 : 1
            //         setDateList(getDateList(startDate, formData.purchasedMonth % 2 === 0 ? 31 : 30));
            //         setMonthList([{ ...availableMonths[10] }, availableMonths[11]]);
            //         setYearList([{ th: 2565, en: 2022 }]);
            //     } else {
            //         setDateList(getDateList(1, 31));
            //         setMonthList([{ ...availableMonths[10] }]);
            //         setYearList([{ th: 2565, en: 2022 }]);
            //     }
            // }

            if (formType === 'formType25' || formType === 'formType27' || formType === 'formType28' || formType === 'formType30' || 'formType32') {
                setFormData({
                    ...formData,
                    engineNumber: engineNumber,
                })
            }

            if (formType === 'formType19') {
                setDateList(getDateList());
                setMonthList([
                    { ...availableMonths[0] },
                    { ...availableMonths[1] },
                    { ...availableMonths[2] },
                    // {...availableMonths[6]},
                    // {...availableMonths[7]},
                    // {...availableMonths[8]},
                    // {...availableMonths[9]},
                    // {...availableMonths[10]},
                    // {...availableMonths[11]},
                ]);
                setYearList([{ th: 2565, en: 2022 }]);
            }

            if (formType === 'formType29') {
                setDateList(getDateList(1, 31));
                setMonthList([
                    // {...availableMonths[0]},
                    { ...availableMonths[2] },
                ]);
                setYearList([{ th: 2565, en: 2022 }]);
            }

            if (formType === 'formType30') {
                setDateList(getDateList(1, 31));
                setMonthList([{ ...availableMonths[11] }]);
                setYearList([{ th: 2565, en: 2022 }]);
            }
        }
    }, [formType])

    useEffect(() => {
        if (formType === 'formType10') {
            setDateList(getDateList(1, 31));
            setMonthList([{ ...availableMonths[11] }]);
            setYearList([{ th: 2566, en: 2023 }]);
        }

        if (formType === 'formType26') {
            if (["RewardFillAddress300", "RewardFillAddress301", "RewardFillAddress302", "RewardFillAddress303"].includes(data?.type)) {
                // setFormData((cur) => ({ ...cur, ...((formData.serviceDate < 31 && formData.serviceMonth === "1") || (formData.serviceDate > 1 && formData.serviceMonth === "2")) && { serviceDate: "" } }))
                // const startDate = formData.serviceMonth === "1" ? getDateList(16, 31) : getDateList(1, 15)
                setDateList(month === 1 ? getDateList(16, 29) : getDateList(1, daysInMonth));
                // setDateList(getDateList(1, 15));
                setMonthList([{...availableMonths[month]}])
                setYearList([{ th: 2568, en: 2025 }]);
            } else {
                setDateList(getDateList(1, daysInMonth));
                setMonthList([{ ...availableMonths[month] }]);
                setYearList([{ th: 2568, en: 2025 }]);
            }
        }

        // if (formType === 'formType5') {
        //     if (["RewardFillAddress178", "RewardFillAddress179", "RewardFillAddress180", "RewardFillAddress181", "RewardFillAddress182"].includes(data?.type)) {
        //         const startDate = formData.purchasedMonth === "11" ? 25 : 1
        //         setDateList(getDateList(startDate, formData.purchasedMonth % 2 === 0 ? 31 : 30));
        //         setMonthList([{ ...availableMonths[10] }, availableMonths[11]]);
        //         setYearList([{ th: 2565, en: 2022 }]);
        //     } else {
        //         setDateList(getDateList(1, 31));
        //         setMonthList([{ ...availableMonths[0] }]);
        //         setYearList([{ th: 2566, en: 2023 }]);
        //     }
        // }
    }, [formType, formData.purchasedMonth, formData.serviceMonth])

    // useEffect(() => {
    //     if (formType === 'formType26') {
    //         if (["RewardFillAddress280", "RewardFillAddress301", "RewardFillAddress302", "RewardFillAddress303"].includes(data?.type)) {
    //
    //             setDateList(getDateList(1, 15));
    //             setMonthList([{...availableMonths[0]}])
    //             setYearList([{ th: 2567, en: 2025 }]);
    //             // setFormData((cur) => {
    //             //     return {
    //             //         ...cur,
    //             //         serviceMonth: "",
    //             //         serviceDate: ""
    //             //     }
    //             // })
    //             // console.log(formData.serviceYear)
    //             // if (formData.serviceYear === "2025" || formData.serviceYear === "2567") {
    //             //     setDateList(getDateList(1, 15));
    //             //     setMonthList([{...availableMonths[0]}])
    //             // } else {
    //             //     setDateList(getDateList(16, 31));
    //             //     setMonthList([{...availableMonths[11]}])
    //             // }
    //             // setYearList([{ th: 2566, en: 2023 }, { th: 2567, en: 2025 }]);
    //         }
    //     }
    //
    // }, [formType, formData.serviceYear])

    const masterModel = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
        }
        const encodedData = encode(param);
        const response = await axios.post(API.GET_MODEL, encodedData);
        setMasterModel(response?.data?.data || []);
    }

    const masterBranch = async () => {
        const param = {
            accesstoken: token,
            lineuserid: profile.userId,
            branchtype: "store"
        }
        const encodedData = encode(param);
        const response = await axios.post(API.GET_BRANCH, encodedData);
        var list = response?.data?.data || [];
        var newlist = [...new Set(list.map(m => m.facadeth))]
        setBranchListAll(list);
        setBranchList(newlist);
    }

    const setMasterModel = (list) => {
        var item = [];
        // don't have 
        if (formType === 'formType2') {
            item = [
                "LX 125 CC",
                "S 125 CC",
                "SPRINT 125 CC",
                "SPRINT 150 CC",
                "PRIMAVERA 150 CC",
                "GTS 150 CC",
                "GTS 300 CC",
                "GTV 300 CC",
            ]
        }
        else if (formType === 'formType19') {
            item = ["SPRINT 150 CC"]
        }
        else if (
            formType === 'formType20'
            || formType === 'formType21'
            || formType === 'formType23'
            || formType === 'formType24'
            // || formType === 'formType25'
        ) {
            item = [
                "LX 125 CC",
                "S 125 CC",
                "SPRINT 125 CC",
                "SPRINT 150 CC",
                "PRIMAVERA 150 CC",
                "GTS SUPER 150 CC",
                "GTS SUPER SPORT 300 HPE",
            ]
        }
        else if (
            formType === 'formType25'
        ) {
            item = [
                "LX 125 CC",
                "LX 150 CC",
                "LXV 125 CC",
                "LXV 150 CC",
                "LT 125 CC",
                "S 125 CC",
                "SPRINT 125 CC",
                "SPRINT 150 CC",
                "PRIMAVERA 125 CC",
                "PRIMAVERA 150 CC",
                "PX 155 CC",
                "GTS 150 CC",
                "GTS 300 CC",
                "SEI GIORNI II EDITION 300 CC",
                "946"
            ]
        }
        else if (formType === 'formType26') {
            if (rewardType === 'RewardFillAddress168') {
                item = [
                    "PRIMAVERA 150 CC",
                    "PRIMAVERA S 150 CC Touring",
                    "PRIMAVERA 150 CC SAFARI SE",
                    "PRIMAVERA 150 CC 75TH ANNI SE",
                    "PRIMAVERA 150 CC PIC NIC EDITION",
                    "PRIMAVERA RED",
                    "PRIMAVERA SEAN WOTHERSPOON",
                ]
            }
            else if (rewardType === 'RewardFillAddress303' || rewardType === 'RewardFillAddress264' || rewardType === 'RewardFillAddress269') {
                item = [
                    "GTS Super 150 cc (MY20-22)",
                    "GTS Super Sport 300 HPE (MY20-22)",
                    "GTS Super Tech 300 HPE (MY20-22)",
                    "Sei Giorni II Edition 300 HPE",
                    "GTS Super Sport 150 cc",
                    "GTS 300 HPE",
                    "GTS Super Tech 300 HPE",
                    "GTV 300 HPE",
                ]
            }
            else if (rewardType === 'RewardFillAddress300' || rewardType === 'RewardFillAddress266' || rewardType === 'RewardFillAddress261' || rewardType === 'RewardFillAddress249') {
                item = [
                    "LX 125 CC",
                    "S 125 CC",
                    "LX 125 CC Touring",
                    "S 125 CC Touring",
                    "LX 125 CC Sand Edition",
                    "S 125 CC Sea Edition",
                    "LX 125 CC Pink Rosa",
                ]
            }
            else if (rewardType === 'RewardFillAddress268' || rewardType === 'RewardFillAddress263' || rewardType === 'RewardFillAddress302') {
                item = [
                    "PRIMAVERA 150 CC",
                    "PRIMAVERA S 150 CC Touring",
                    "PRIMAVERA S 150 CC Touring II",
                    "PRIMAVERA RED",
                    "PRIMAVERA COLOR VIBE",
                    "PRIMAVERA Disney Mickey Mouse"
                ]
            }
            else if (rewardType === 'RewardFillAddress47' || rewardType === 'RewardFillAddress157') {
                item = [
                    "PRIMAVERA 150 CC",
                    "PRIMAVERA S 150 CC",
                    "PRIMAVERA S 150 CC Touring",
                    "PRIMAVERA 150 CC SAFARI SE",
                    "PRIMAVERA 150 CC 75TH ANNI SE",
                    "PRIMAVERA 150 CC PIC NIC EDITION",
                    "PRIMAVERA RED",
                    "PRIMAVERA SEAN WOTHERSPOON",
                ]
            }
            else if (rewardType === 'RewardFillAddress48' || rewardType === 'RewardFillAddress91') {
                item = [
                    "GTS SUPER SPORT 300 HPE",
                    "GTS SUPER TECH 300 HPE",
                    "GTS SUPER 300 CC Racing Sixties",
                    "GTS 75TH ANNI SE",
                    "SEI GIORNI II EDITION 300 HPE"
                ]
            }
            else if (rewardType === 'RewardFillAddress96' || rewardType === 'RewardFillAddress156' || rewardType === "RewardFillAddress164" || rewardType === "RewardFillAddress167") {
                item = [
                    "Justin Bieber X Vespa 150",
                    "SPRINT 150 CC",
                    "SPRINT 150 CC RACING SIXTIES",
                    "SPRINT 150 CC 10TH ANNI",
                    "SPRINT S 150 CC",
                    "SPRINT S 150 CC (TFT)",
                    "GTS SUPER 150 CC",
                    "GTS SUPER SPORT 300 HPE",
                    "GTS SUPER TECH 300 HPE",
                    "GTS SUPER 300 CC Racing Sixties",
                    "GTS 75TH ANNI SE",
                    "SEI GIORNI II EDITION 300 HPE"
                ]
            }
            else if (rewardType === 'RewardFillAddress119') {
                item = [
                    "PRIMAVERA 150 CC",
                    "PRIMAVERA S 150 CC",
                    "PRIMAVERA S 150 CC Touring",
                    "PRIMAVERA 150 CC SAFARI SE",
                    "PRIMAVERA 150 CC 75TH ANNI SE",
                    "PRIMAVERA 150 CC PIC NIC EDITION",
                    "PRIMAVERA RED",
                    "PRIMAVERA SEAN WOTHERSPOON",
                    "GTS SUPER 150 CC",
                    "GTS SUPER SPORT 300 HPE",
                    "GTS SUPER TECH 300 HPE",
                    "GTS SUPER 300 CC Racing Sixties",
                    "GTS 75TH ANNI SE",
                    "SEI GIORNI II EDITION 300 HPE"
                ]
            }
            else if (rewardType === 'RewardFillAddress95' || rewardType === 'RewardFillAddress154' || rewardType === "RewardFillAddress163" || rewardType === "RewardFillAddress165") {
                item = [
                    "S 125 CC",
                    "SPRINT 125 CC",
                ]
            }
            else if (rewardType === 'RewardFillAddress158' || rewardType === 'RewardFillAddress169') {
                item = [
                    "LX 125 CC",
                ]
            }
            else if (rewardType === 'RewardFillAddress159' || rewardType === 'RewardFillAddress170') {
                item = [
                    "LX 125 CC Touring",
                    "S 125 CC Touring",
                ]
            }
            else if (rewardType === 'RewardFillAddress100' || rewardType === 'RewardFillAddress104') {
                item = [
                    "SPRINT S 150 CC",
                    "SPRINT S 150 CC (TFT)",
                    "GTS SUPER 150 CC",
                ]
            }
            else if (rewardType === 'RewardFillAddress117') {
                item = [
                    "SPRINT 150 CC",
                    "SPRINT 150 CC RACING SIXTIES",
                    "SPRINT 150 CC 10TH ANNI",
                    "SPRINT S 150 CC",
                    "SPRINT S 150 CC (TFT)",
                ]
            }
            else if (rewardType === 'RewardFillAddress267' || rewardType === 'RewardFillAddress186') {
                item = [
                    "SPRINT 125 CC",
                    "SPRINT 150 CC",
                    "SPRINT S 150 CC",
                    "SPRINT S 150 CC (TFT)",
                    "Justin Bieber X Vespa 150",
                ]
            }
            else if (rewardType === 'RewardFillAddress301' || rewardType === 'RewardFillAddress262' || rewardType === 'RewardFillAddress213') {
                item = [
                    "SPRINT 125 CC",
                    "SPRINT 150 CC",
                    "SPRINT S 150 CC",
                    "SPRINT S 150 CC (TFT)",
                    "Justin Bieber X Vespa 150"
                ]
            }
            else if (rewardType === 'RewardFillAddress174') {
                item = [
                    "LX 125 CC",
                    "S 125 CC",
                    "SPRINT 125 CC"
                ]
            }
            else if (rewardType === 'RewardFillAddress175') {
                item = [
                    "Justin Bieber X Vespa 150",
                    "SPRINT 150 CC",
                    "SPRINT 150 CC RACING SIXTIES",
                    "SPRINT 150 CC 10TH ANNI",
                    "SPRINT S 150 CC",
                    "SPRINT S 150 CC (TFT)",
                    "PRIMAVERA 150 CC",
                    "PRIMAVERA S 150 CC Touring",
                    "PRIMAVERA RED",
                    "PRIMAVERA SEAN WOTHERSPOON",
                ]
            }
            else if (rewardType === 'RewardFillAddress229' || rewardType === 'RewardFillAddress220' || rewardType === 'RewardFillAddress207') {
                item = [
                    "GTS SUPER 150 CC",
                    "GTS SUPER SPORT 300 HPE",
                    "GTS SUPER TECH 300 HPE",
                    "SEI GIORNI II EDITION 300 HPE"
                ]
            }
        }
        else if (formType === 'formType10') {
            if (rewardType === 'RewardFillAddress253') {
                item = [
                    "LX",
                    "S",
                    "SPRINT",
                    "PRIMAVERA",
                    "GTS",
                ]
            }
        }
        else if (formType === 'formType37' || formType === 'formType38') {
            if (rewardType === 'RewardFillAddress291' || rewardType === 'RewardFillAddress296' || rewardType === 'RewardFillAddress297') {
                item = [
                    "LX 125 CC",
                    "S 125 CC",
                    "SPRINT 125 CC",
                    "SPRINT 150 CC",
                    "PRIMAVERA 150 CC",
                    "GTS 150 CC",
                    "GTS 300 CC",
                    "GTV 300 CC",
                ]
            }
        }
        else if (formType === 'formType31' || formType === 'formType33' || formType === 'formType34' || formType === 'formType35') {
            if (rewardType === 'RewardFillAddress253') {
                item = [
                    "LX",
                    "S",
                    "SPRINT",
                    "PRIMAVERA",
                    "GTS",
                ]
            } else if (rewardType === 'RewardFillAddress284') {
                item = [
                    "LX",
                    "S",
                    "SPRINT",
                    "PRIMAVERA",
                    "GTS (MY18/19)",
                    "GTS",
                ]
            }
            else {
                item = [
                    "LX 125 CC",
                    "S 125 CC",
                    "SPRINT 125 CC",
                    "SPRINT 150 CC",
                    "PRIMAVERA 150 CC",
                    "GTS SUPER 150 CC",
                    "GTS SUPER SPORT 300 HPE",
                ]
            }
        }
        else if (
            formType === 'formType27' || 'formType32'
        ) {
            item = [
                "LX 125 CC",
                "LX 150 CC",
                "LXV 125 CC",
                "LXV 150 CC",
                "LT 125 CC",
                "S 125 CC",
                "SPRINT 125 CC",
                "SPRINT 150 CC",
                "PRIMAVERA 125 CC",
                "PRIMAVERA 150 CC",
                "PX 125 CC",
                "GTS 150 CC",
                "GTS 300 CC",
                "SEI GIORNI II EDITION 300 HPE",
            ]
        }
        else if (
            formType === 'formType28'
        ) {
            item = [
                "LX 125 CC",
                "LX 150 CC",
                "LXV 125 CC",
                "LXV 150 CC",
                "LT 125 CC",
                "S 125 CC",
                "SPRINT 125 CC",
                "SPRINT 150 CC",
                "PRIMAVERA 125 CC",
                "PRIMAVERA 150 CC",
                "PX 125 CC",
                // "PX 150 CC",
                "GTS 150 CC",
                "GTS 300 CC",
                "SEI GIORNI II EDITION 300 CC",
                "SEI GIORNI II EDITION 300 HPE",
            ]
        }
        else if (
            formType === 'formType30'
        ) {
            item = [
                "S 125 CC",
                "LX 125 CC",
                "SPRINT 125 CC",
                "LXV 125 CC",
                "LT 125 CC",
                "PRIMAVERA 125 CC",
                "PX 125 CC",

                "SPRINT 150 CC",
                "PRIMAVERA 150 CC",
                "GTS 150 CC",
                "LX 150 CC",
                "LXV 150 CC",

                "GTS 300 CC",
                "SEI GIORNI II EDITION 300 CC",
                "SEI GIORNI II EDITION 300 HPE",
            ]
        }

        if (formType === 'formType5') {
            // var filter = [
            //     { titleen: 'GTS SUPER ABS * (MY 18)', titlesub1: 'GTS SUPER ABS (MY 18)', titlesub2: '300 CC' },
            //     { titleen: 'GTS SUPER SPORT * ABS (MY 19)', titlesub1: 'GTS SUPER SPORT ABS (MY 19)', titlesub2: '300 CC' }
            // ];
            var mapList = ['GTS SUPER 300 CC (MY 18)', 'GTS SUPER SPORT 300 CC (MY 19)'];
            // setModelListAll(filter);
            setModelList(mapList);
        }
        else if (formType === 'formType10') {
            var filter = list.filter(f => ~item.indexOf(f.titleen) < 0);
            // var mapList = filter.map(f => f.titleen);
            var mapList = item
            setModelList(mapList);
            setModelListAll(filter);
        }
        else if (formType === 'formType29') {
            setModelList(['LX 125 CC']);
            setModelListAll([{ titlesub1: 'LX', titlesub2: '125 CC' }]);
        }
        else if (formType === 'formType26') {
            var filter = list.filter(f => ~item.indexOf(f.titleen) < 0);
            // var mapList = filter.map(f => f.titleen);
            var mapList = item
            setModelList(mapList);
            setModelListAll(filter);
        }
        else if (formType === 'formType31' || formType === 'formType33' || formType === 'formType34' || formType === 'formType35' || formType === 'formType37' || formType === 'formType38') {
            var filter = list.filter(f => ~item.indexOf(f.titleen) < 0);
            // var mapList = filter.map(f => f.titleen);
            var mapList = item
            setModelList(mapList);
            setModelListAll(filter);
        }
        else if (formType === 'formType20') {
            var filter = list.filter(f => ~item.indexOf(f.titleen) < 0);
            var mapList = filter.map(f => f.titleen);
            setModelList(mapList);
            setModelListAll(filter);
        }
        else {
            var filter = list.filter(f => ~item.indexOf(f.titleen) < 0);
            setModelListAll(filter);
            var mapList = [...new Set(filter.map(m => m.titlesub1))];
            if (["RewardFillAddress274", "RewardFillAddress275"].includes(rewardType)) {
                mapList.push("GTV")
            }
            setModelList(mapList);
        }
    }

    useEffect(() => {
        setFormData({
            ...formData,
            size: ''
        })
        if (formData.model) {
            var filter = modelListAll.filter(f => f.titlesub1 === formData.model);
            var mapList = [...new Set(filter.map(m => m.titlesub2))];
            if (["RewardFillAddress274", "RewardFillAddress275"].includes(rewardType)) {
                if (["S"].includes(formData.model)) {
                    mapList.push("150 CC")
                } else if (formData.model === "GTV") {
                    mapList.push("300 CC")
                }
            }
            setSizeList(mapList);
        }
        else {
            setSizeList([]);
        }

        if (formData.model && formType === 'formType5') {
            if (rewardType === "RewardFillAddress221") {
                if (formData.model === 'GTS SUPER 300 CC (MY 18)') {
                    setVoucherSet('set1');
                    setShowVoucher(<>
                        &bull; Voucher 15,000 บาท <br />
                        &bull; Vespa Friend-Trip <br />
                        &bull; ผ้ายางรองพื้น<br />
                        &bull; Vespa Welcome Kit <br />
                        &bull; ประกันภัยชั้น 1 (1 ปี) <br />
                        &bull; ชุดแต่ง Accessories
                    </>);
                    setImage(C05AW1MAR66);
                }
                else {
                    setVoucherSet('set2');
                    setShowVoucher(<>
                        &bull; Voucher 15,000 บาท <br />
                        &bull; Vespa Friend-Trip <br />
                        &bull; ผ้ายางรองพื้น<br />
                        &bull; Vespa Welcome Kit <br />
                        &bull; ประกันภัยชั้น 1 (1 ปี) <br />
                    </>);
                    setImage(C05AW2MAR66);
                }

            } else {
                if (~['GTS SUPER 300 CC (MY 18)'].indexOf(formData.model) < 0) {
                    setVoucherSet('set1');
                    setShowVoucher(<>
                        &bull; Special Voucher 1,000 บาท<br />
                        &bull; Voucher 15,000 บาท<br />
                        &bull; Vespa Friend-Trip<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; ประกันภัยชั้น 1 (1 ปี)<br />
                        &bull; ชุดแต่ง Accessories
                    </>);
                    setImage(C13AW1FEB66);
                }
                else {
                    setVoucherSet('set2');
                    setShowVoucher(<>
                        &bull; Special Voucher 1,000 บาท<br />
                        &bull; Voucher 15,000 บาท<br />
                        &bull; Vespa Friend-Trip<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; ประกันภัยชั้น 1 (1 ปี)
                    </>);
                    setImage(C13AW2FEB66);
                }
            }
        }
        if (formData.model && formType === 'formType37'){
            if (rewardType === 'RewardFillAddress291') {
                if (["LX 125 CC", "S 125 CC", "SPRINT 125 CC",].includes((formData.model))) {
                    setVoucherSet('set1');
                    setShowVoucher(<>
                        &bull; ส่วนลด 200 บาท
                    </>);
                    setImage(F1);
                } else if (["SPRINT 150 CC", "PRIMAVERA 150 CC", "GTS 150 CC"].includes((formData.model))) {
                    setVoucherSet('set2');
                    setShowVoucher(<>
                        &bull; ส่วนลด 300 บาท
                    </>);
                    setImage(F2);
                } else {
                    setVoucherSet('set3');
                    setShowVoucher(<>
                        &bull; ส่วนลด 500 บาท
                    </>);
                    setImage(F3);
                }
            }
        }
        if (formData.model && formType === 'formType38'){
            if (rewardType === 'RewardFillAddress297') {
                setVoucherSet('set1');
                setShowVoucher(<>
                    &bull; ส่วนลด 500 บาท
                </>);
            }
        }
        if (formData.model && formType === 'formType26') {
            if (rewardType === 'RewardFillAddress302') {
                if (formData.model === "PRIMAVERA COLOR VIBE") {
                    setVoucherSet('set2');
                    setShowVoucher(<>
                        &bull; Voucher ตามมูลค่าที่กำหนด<br />
                        &bull; Bumshaker Hype Box และชุดแต่ง<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C04AW2SEP66);
                } else if (formData.model === "PRIMAVERA Disney Mickey Mouse") {
                    setVoucherSet('set3');
                    setShowVoucher(<>
                        &bull; Welcome Kit
                    </>);
                    setImage(C04AW3SEP66);
                } else {
                    setVoucherSet('set1');
                    setShowVoucher(<>
                        &bull; Voucher ตามมูลค่าที่กำหนด<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C04AW1SEP66);
                }
            }
            else if (rewardType === 'RewardFillAddress303') {
                if (formData.model === "GTV 300 HPE") {
                    setVoucherSet('set3');
                    setShowVoucher(<>
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C05AW2SEP66);
                }
                else if (~["GTS 300 HPE", "GTS Super Tech 300 HPE"].indexOf(formData.model) < 0) {
                    setVoucherSet('set2');
                    setShowVoucher(<>
                        &bull; Voucher ตามมูลค่าที่กำหนด<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C05AW1SEP66);
                }
                else {
                    setVoucherSet('set1');
                    setShowVoucher(<>
                        &bull; Voucher ตามมูลค่าที่กำหนด<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C05AW1SEP66);
                }
            }
            else if (rewardType === 'RewardFillAddress264') {
                if (formData.model === "GTS Super 150 cc (MY20-22)") {
                    setVoucherSet('set1');
                    setShowVoucher(<>
                        &bull; Voucher 5,000 บาท<br />
                        &bull; ผ้ายางรองพื้น กับกระจกบังลมสั้น<br />
                        &bull; Vespa Mug<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C08AW1AUG66);
                }
                else if (~["GTS Super Sport 300 HPE (MY20-22)", "GTS Super Tech 300 HPE (MY20-22)", "Sei Giorni II Edition 300 HPE"].indexOf(formData.model) < 0) {
                    setVoucherSet('set2');
                    setShowVoucher(<>
                        &bull; Voucher 10,000 บาท<br />
                        &bull; ชุดแต่ง Accessories<br />
                        &bull; Vespa Mug<br />
                        &bull; Servizio Package<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C08AW1AUG66);
                }
                else if (formData.model === "GTS Super Sport 150 cc") {
                    setVoucherSet('set3');
                    setShowVoucher(<>
                        &bull; Voucher 5,000 บาท<br />
                        &bull; Vespa Mug<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C08AW1AUG66);
                }
                else if (~["GTS 300 HPE", "GTS Super Tech 300 HPE"].indexOf(formData.model) < 0) {
                    setVoucherSet('set4');
                    setShowVoucher(<>
                        &bull; Voucher 10,000 บาท<br />
                        &bull; Vespa Mug<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C08AW1AUG66);
                }
                else {
                    setVoucherSet('set5');
                    setShowVoucher(<>
                        &bull; ชุดแต่ง Orange Flyscreen / Racing Cover<br />
                        &bull; Vespa Mug<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C08AW2AUG66);
                }
            }
            else if (rewardType === 'RewardFillAddress269') {
                if (formData.model === "GTS Super 150 cc (MY20-22)") {
                    setVoucherSet('set1');
                    setShowVoucher(<>
                        &bull; Voucher 5,000 บาท<br />
                        &bull; ผ้ายางรองพื้น กับกระจกบังลมสั้น<br />
                        &bull; Vespa Happy Rain<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C05AW1SEP66);
                }
                else if (~["GTS Super Sport 300 HPE (MY20-22)", "GTS Super Tech 300 HPE (MY20-22)", "Sei Giorni II Edition 300 HPE"].indexOf(formData.model) < 0) {
                    setVoucherSet('set2');
                    setShowVoucher(<>
                        &bull; Voucher 10,000 บาท<br />
                        &bull; ชุดแต่ง Accessories<br />
                        &bull; Vespa Happy Rain<br />
                        &bull; Servizio Package<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C05AW1SEP66);
                }
                else if (formData.model === "GTS Super Sport 150 cc") {
                    setVoucherSet('set3');
                    setShowVoucher(<>
                        &bull; Voucher 5,000 บาท<br />
                        &bull; Vespa Happy Rain<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C05AW1SEP66);
                }
                else if (~["GTS 300 HPE", "GTS Super Tech 300 HPE"].indexOf(formData.model) < 0) {
                    setVoucherSet('set4');
                    setShowVoucher(<>
                        &bull; Voucher 10,000 บาท<br />
                        &bull; Vespa Happy Rain<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C05AW1SEP66);
                }
                else {
                    setVoucherSet('set5');
                    setShowVoucher(<>
                        &bull; ชุดแต่ง Orange Flyscreen / Racing Cover<br />
                        &bull; Vespa Happy Rain<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C05AW2SEP66);
                }
            }
            else if (rewardType === 'RewardFillAddress261') {
                if (formData.model === 'LX 125 CC') {
                    setVoucherSet('set1');
                    setImage(C05AW1AUG66);
                    setShowVoucher(<>
                        &bull; Voucher 3,000 บาท<br />
                        &bull; Candy Corduroy<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                } else if (formData.model === 'S 125 CC') {
                    setVoucherSet('set2');
                    setImage(C05AW2AUG66);
                    setShowVoucher(<>
                        &bull; Voucher 3,000 บาท<br />
                        &bull; Candy Corduroy<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                }
                else if (~["LX 125 CC Touring", "S 125 CC Touring",].indexOf(formData.model) < 0) {
                    setVoucherSet('set3');
                    setImage(C05AW3AUG66);
                    setShowVoucher(<>
                        &bull; Voucher 3,000 บาท<br />
                        &bull; Touring Bag<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);
                } else {
                    setVoucherSet('set4');
                    setImage(C05AW4AUG66);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Sand & Sea Premium Set<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit
                    </>);

                }
            }
            else if (rewardType === 'RewardFillAddress263') {
                if (~["PRIMAVERA 150 CC", "PRIMAVERA S 150 CC Touring", "PRIMAVERA S 150 CC Touring II"].indexOf(formData.model) < 0) {
                    setVoucherSet('set1');
                    setShowVoucher(<>
                        &bull; Voucher 4,000 บาท<br />
                        &bull; Mug & Cap<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C07AW1AUG66);
                } else if (formData.model === 'PRIMAVERA RED') {
                    setVoucherSet('set2');
                    setShowVoucher(<>
                        &bull; Mug & Cap<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C07AW1AUG66);
                } else {
                    setVoucherSet('set3');
                    setShowVoucher(<>
                        &bull; Bumshaker Hype Box และชุดแต่ง<br />
                        &bull; Welcome Kit
                    </>);
                    setImage(C07AW2AUG66);
                }
            }
            else if (rewardType === 'RewardFillAddress48' || rewardType === 'RewardFillAddress91') {
                if (formData.model === 'GTS SUPER SPORT 300 HPE') {
                    setVoucherSet('set1');
                    setImage(VoucherGTS1Img);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Vespa Pack & Go<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300 HPE
                    </>);
                }
                else if (formData.model === 'GTS SUPER TECH 300 HPE') {
                    setVoucherSet('set2');
                    setImage(VoucherGTS2Img);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Vespa Pack & Go<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300 HPE
                    </>);
                }
                else if (formData.model === 'GTS SUPER 300 CC Racing Sixties') {
                    setVoucherSet('set3');
                    setImage(VoucherGTS3Img);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Vespa Pack & Go<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE Racing Sixties
                    </>);
                }
                else if (formData.model === 'GTS 75TH ANNI SE') {
                    setVoucherSet('set4');
                    setImage(VoucherGTS4Img);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Vespa Pack & Go<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th Anniversary Special Edition
                    </>);
                }
                else if (formData.model === 'SEI GIORNI II EDITION 300 HPE') {
                    setVoucherSet('set5');
                    setImage(VoucherGTS5Img);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Vespa Pack & Go<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II Edition 300 HPE
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress96') {
                if (formData.model === 'GTS SUPER SPORT 300 HPE') {
                    setVoucherSet('set3');
                    setImage(SGTS3Img);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Vespa Pack & Go<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300 HPE
                    </>);
                }
                else if (formData.model === 'GTS SUPER TECH 300 HPE') {
                    setVoucherSet('set4');
                    setImage(SGTS3Img);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Vespa Pack & Go<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300 HPE
                    </>);
                }
                else if (formData.model === 'GTS SUPER 300 CC Racing Sixties') {
                    setVoucherSet('set5');
                    setImage(SGTS5Img);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Vespa Pack & Go<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE Racing Sixties
                    </>);
                }
                else if (formData.model === 'GTS 75TH ANNI SE') {
                    setVoucherSet('set6');
                    setImage(SGTS6Img);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Vespa Pack & Go<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th Anniversary Special Edition
                    </>);
                }
                else if (formData.model === 'SEI GIORNI II EDITION 300 HPE') {
                    setVoucherSet('set7');
                    setImage(SGTS7Img);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Vespa Pack & Go<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II Edition 300 HPE
                    </>);
                }
                else if (~["PRIMAVERA 150 CC PIC NIC EDITION", "PRIMAVERA RED",
                    "PRIMAVERA SEAN WOTHERSPOON"
                ].indexOf(formData.model) < 0) {
                    setVoucherSet('set2');
                    setImage(SGTS2Img);
                    setShowVoucher(<>
                        &bull; Vespa Pack & Go<br />
                        &bull; Vespa Welcome Kit
                    </>);
                } else {
                    setVoucherSet('set1');
                    setImage(SGTS1Img);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Vespa Pack & Go<br />
                        &bull; Vespa Welcome Kit
                    </>);
                }
            }

            else if (rewardType === 'RewardFillAddress220') {
                if (~["GTS SUPER 150 CC"].indexOf(formData.model) < 0) {
                    setVoucherSet('set2');
                    setImage(C04AW2MAR66);
                    setShowVoucher(<>
                        &bull; ผ้ายางรองพื้น<br />
                        &bull; Vespa Friend-Trip<br />
                        &bull; Vespa Welcome Kit
                    </>);
                } else {
                    setVoucherSet('set1');
                    setImage(C04AW1MAR66);
                    setShowVoucher(<>
                        &bull; ผ้ายางรองพื้น<br />
                        &bull; Vespa Friend-Trip<br />
                        &bull; ชุดแต่ง Accessories<br />
                        &bull; Vespa Welcome Kit
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress207') {
                if (~["GTS SUPER 150 CC"].indexOf(formData.model) < 0) {
                    setVoucherSet('set2');
                    setImage(C04AW2FEB66);
                    setShowVoucher(<>
                        &bull; Vespa Friend-Trip<br />
                        &bull; Vespa Welcome Kit
                    </>);
                } else {
                    setVoucherSet('set1');
                    setImage(C04AW1FEB66);
                    setShowVoucher(<>
                        &bull; Vespa Friend-Trip<br />
                        &bull; ชุดแต่ง Accessories<br />
                        &bull; Vespa Welcome Kit
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress100' || rewardType === 'RewardFillAddress104') {
                if (~["SPRINT S 150 CC", "SPRINT S 150 CC (TFT)", "GTS SUPER 150 CC"].indexOf(formData.model) < 0) {
                    setVoucherSet('set1');
                    setImage(MATImg);
                    setShowVoucher(<>
                        &bull; ผ้ายางรองพื้น<br />
                        &bull; Vespa Summer Ride<br />
                        &bull; Vespa Welcome Kit
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress158' || rewardType === 'RewardFillAddress169') {
                if (~["LX 125 CC"].indexOf(formData.model) < 0) {
                    setVoucherSet('set1');
                    setImage(C05AW1NOV65);
                    setShowVoucher(<>
                        &bull; Bumshaker Candy Corduroy<br />
                        &bull; Vespa Welcome Kit<br />
                        (คูปองนี้ไม่สามารถใช้ร่วมกับรุ่น LX 125 cc Touring)
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress117') {
                if (~["SPRINT S 150 CC", "SPRINT S 150 CC (TFT)"].indexOf(formData.model) < 0) {
                    setVoucherSet('set2');
                    setImage(CC1502Img);
                    setShowVoucher(<>
                        &bull; Bumshaker Duo Set<br />
                        &bull; Vespa Welcome Kit
                    </>);
                } else {
                    setVoucherSet('set1');
                    setImage(CC1501Img);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Bumshaker Duo Set<br />
                        &bull; Vespa Welcome Kit
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress156' || rewardType === 'RewardFillAddress164') {
                if (formData.model === 'GTS SUPER SPORT 300 HPE') {
                    setVoucherSet('set3');
                    rewardType === 'RewardFillAddress164' ? setImage(AW4) : setImage(SGTS3Img);
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300 HPE
                    </>);
                }
                else if (formData.model === 'GTS SUPER TECH 300 HPE') {
                    setVoucherSet('set4');
                    rewardType === 'RewardFillAddress164' ? setImage(AW4) : setImage(SGTS3Img);
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300 HPE
                    </>);
                }
                else if (formData.model === 'GTS SUPER 300 CC Racing Sixties') {
                    setVoucherSet('set5');
                    rewardType === 'RewardFillAddress164' ? setImage(AW4) : setImage(SGTS3Img);
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE Racing Sixties
                    </>);
                }
                else if (formData.model === 'GTS 75TH ANNI SE') {
                    setVoucherSet('set6');
                    rewardType === 'RewardFillAddress164' ? setImage(AW4) : setImage(SGTS3Img);
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th Anniversary Special Edition
                    </>);
                }
                else if (formData.model === 'SEI GIORNI II EDITION 300 HPE') {
                    setVoucherSet('set7');
                    rewardType === 'RewardFillAddress164' ? setImage(AW4) : setImage(SGTS3Img);
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II Edition 300 HPE
                    </>);
                }
                else if (formData.model === 'Justin Bieber X Vespa 150') {
                    setVoucherSet('set2');
                    rewardType === 'RewardFillAddress164' ? setImage(AW3) : setImage(SGTS2Img);
                    setShowVoucher(<>
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
                else if (~["SPRINT 150 CC", "SPRINT S 150 CC"].indexOf(formData.model) < 0) {
                    setVoucherSet('set8');
                    rewardType === 'RewardFillAddress164' ? setImage(AW1) : setImage(SGTS2Img);
                    setShowVoucher(<>
                        &bull; Voucher 500 บาท<br />
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
                else {
                    setVoucherSet('set1');
                    setImage(AW2);
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress159' || rewardType === 'RewardFillAddress170') {
                setVoucherSet('set1');
                setImage(C06AW1NOV65);
                setShowVoucher(<>
                    &bull; Bumshaker Touring Bag<br />
                    &bull;  Vespa Welcome Kit
                </>);
            }
            else if (rewardType === 'RewardFillAddress131') {
                if (formData.model === 'LX 125 CC') {
                    setVoucherSet('set1');
                    setImage(ACC1Img);
                    setShowVoucher(<>
                        &bull; กันล้มหลังสีโครเมี่ยม<br />
                        &bull; ตะแกรงหลังพับได้สีโครเมี่ยม<br />
                        &bull; ฟรี Bumshaker Kid Helmet 1 ใบ (คละสี)<br />
                    </>);
                } else if (formData.model === 'S 125 CC') {
                    setVoucherSet('set2');
                    setImage(ACC2Img);
                    setShowVoucher(<>
                        &bull; กันล้มหลังสีดำด้าน<br />
                        &bull; ตะแกรงหลังพับได้สีดำด้าน<br />
                        &bull; ฟรี Bumshaker Kid Helmet 1 ใบ (คละสี)<br />
                    </>);
                }
                else if (~["SPRINT 125 CC", "SPRINT 150 CC"].indexOf(formData.model) < 0) {
                    setVoucherSet('set3');
                    setImage(ACC3Img);
                    setShowVoucher(<>
                        &bull; กระจกบังลมสั้นสีใส<br />
                        &bull; กันล้มหน้าสีดำด้าน<br />
                        &bull; ฟรี Bumshaker Kid Helmet 1 ใบ (คละสี)<br />
                    </>);
                }
                else if (formData.model === 'PRIMAVERA 150 CC') {
                    setVoucherSet('set4');
                    setImage(ACC4Img);
                    setShowVoucher(<>
                        &bull; กระจกบังลมสั้นสีสโม๊ค<br />
                        &bull; กันล้มหน้าสีโครเมี่ยม<br />
                        &bull; ฟรี Bumshaker Kid Helmet 1 ใบ (คละสี)<br />
                    </>);
                }
                else {
                    setVoucherSet('set5');
                    setImage(ACC5Img);
                    setShowVoucher(<>
                        &bull; กระจกบังลมสั้นสีสโม๊ค<br />
                        &bull; ตะแกรงหลังพับได้สีโครเมี่ยม<br />
                        &bull; ฟรี Bumshaker Kid Helmet 1 ใบ (คละสี)<br />
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress154' || rewardType === 'RewardFillAddress163' || rewardType === 'RewardFillAddress165') {
                if (~["S 125 CC"].indexOf(formData.model) < 0) {
                    setVoucherSet('set1');
                    rewardType === 'RewardFillAddress163' ? setImage(AW1OCT22) : setImage(C01AW1NOV65);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Miniature Vespa<br />
                        &bull; Vespa Welcome Kit<br />
                        (คูปองนี้ไม่สามารถใช้ได้กับรุ่น S 125 cc Touring)
                    </>);
                } else {
                    setVoucherSet('set2');
                    rewardType === 'RewardFillAddress163' ? setImage(AW2OCT22) : setImage(C01AW1NOV65);
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Miniature Vespa<br />
                        &bull; Vespa Welcome Kit
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress167') {
                if (formData.model === 'Justin Bieber X Vespa 150') {
                    setVoucherSet('set3');
                    setImage(C03AW3NOV65)
                    setShowVoucher(<>
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
                else if (formData.model === 'GTS SUPER SPORT 300 HPE') {
                    setVoucherSet('set4');
                    setImage(C03AW4NOV65)
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS Super Sport 300 HPE
                    </>);
                }
                else if (formData.model === 'GTS SUPER TECH 300 HPE') {
                    setVoucherSet('set5');
                    setImage(C03AW4NOV65)
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS Super Tech 300 HPE
                    </>);
                }
                else if (formData.model === 'GTS SUPER 300 CC Racing Sixties') {
                    setVoucherSet('set6');
                    setImage(C03AW4NOV65)
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS Super 300 HPE Racing Sixties
                    </>);
                }
                else if (formData.model === 'GTS 75TH ANNI SE') {
                    setVoucherSet('set7');
                    setImage(C03AW4NOV65)
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น GTS 300 HPE 75th Anniversary Special Edition
                    </>);
                }
                else if (formData.model === 'SEI GIORNI II EDITION 300 HPE') {
                    setVoucherSet('set8');
                    setImage(C03AW4NOV65)
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                        &bull; Servizio Package<br />
                        &bull; ชุดแต่ง Accessories รุ่น Sei Giorni II Edition 300 HPE
                    </>);
                }
                else if (~["SPRINT 150 CC", "SPRINT S 150 CC"].indexOf(formData.model) < 0) {
                    setVoucherSet('set1');
                    setImage(C03AW1NOV65)
                    setShowVoucher(<>
                        &bull; Voucher 1,000 บาท<br />
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
                else {
                    setVoucherSet('set2');
                    setImage(C03AW2NOV65);
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress168') {
                if (formData.model === "PRIMAVERA S 150 CC Touring") {
                    setVoucherSet('set1');
                    setImage(C04AW1NOV65)
                    setShowVoucher(<>
                        &bull; Voucher 1,000 บาท<br />
                        &bull; Bumshaker Tote to go<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
                else if (~["PRIMAVERA RED", "PRIMAVERA SEAN WOTHERSPOON"].indexOf(formData.model) < 0) {
                    setVoucherSet('set3');
                    setImage(C04AW3NOV65)
                    setShowVoucher(<>
                        &bull; Vespa Welcome Kit
                    </>);
                }
                else {
                    setVoucherSet('set2');
                    setImage(C04AW2NOV65)
                    setShowVoucher(<>
                        &bull; Bumshaker Tote to go<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress301') {
                setVoucherSet('set1');
                setImage(C03AW1SEP66)
                setShowVoucher(<>
                    &bull; Voucher ตามมูลค่าที่กำหนด<br />
                    &bull; Welcome Kit
                </>);
                // if (formData.model === "SPRINT 125 CC") {
                //     setVoucherSet('set1');
                //     setImage(C03AW1SEP66)
                //     setShowVoucher(<>
                //         &bull; Voucher 5,000 บาท<br />
                //         &bull; Vespa Travelista Kit<br />
                //         &bull; ค่าจดทะเบียน และ พ.ร.บ.<br />
                //         &bull; ประกันรถหาย<br />
                //         &bull; Vespa Welcome Kit
                //     </>);
                // }
                // else {
                //     setVoucherSet('set2');
                //     setImage(C03AW1SEP66)
                //     setShowVoucher(<>
                //         &bull; Voucher 5,000 บาท<br />
                //         &bull; Vespa Travelista Kit<br />
                //         &bull; ค่าจดทะเบียน และ พ.ร.บ.<br />
                //         &bull; ประกันรถหาย<br />
                //         &bull; Vespa Welcome Kit
                //     </>);
                // }
            }
            else if (rewardType === 'RewardFillAddress262') {
                if (formData.model === "SPRINT S 150 CC (TFT)") {
                    setVoucherSet('set2');
                    setImage(C06AW1AUG66)
                    setShowVoucher(<>
                        &bull; Vespa Mug<br />
                        &bull; Welcome Kit<br />
                    </>);
                } else {
                    setVoucherSet('set1');
                    setImage(C06AW1AUG66)
                    setShowVoucher(<>
                        &bull; Voucher 4,000 บาท<br />
                        &bull; Vespa Mug<br />
                        &bull; Welcome Kit<br />
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress213') {
                if (formData.model === "SPRINT 125 CC") {
                    setVoucherSet('set1');
                    setImage(C10AW1FEB66)
                    setShowVoucher(<>
                        &bull; Special Voucher 1,000 บาท<br />
                        &bull; Voucher 3,000 บาท<br />
                        &bull; Vespa Friend-Trip<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
                else if (~["SPRINT 150 CC", "SPRINT S 150 CC"].indexOf(formData.model) < 0) {
                    setVoucherSet('set2');
                    setImage(C10AW2FEB66)
                    setShowVoucher(<>
                        &bull; Special Voucher 1,000 บาท<br />
                        &bull; Voucher 2,000 บาท<br />
                        &bull; Vespa Friend-Trip<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                } else {
                    setVoucherSet('set3');
                    setImage(C10AW3FEB66)
                    setShowVoucher(<>
                        &bull; Special Voucher 1,000 บาท<br />
                        &bull; Voucher 1,000 บาท<br />
                        &bull; Vespa Friend-Trip<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress175') {
                if (~["SPRINT 150 CC", "SPRINT S 150 CC", "SPRINT 150 CC RACING SIXTIES", "SPRINT 150 CC 10TH ANNI"].indexOf(formData.model) < 0) {
                    setVoucherSet('set1');
                    setImage(C11AW1NOV65)
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
                else if (formData.model === "SPRINT S 150 CC (TFT)") {
                    setVoucherSet('set2');
                    setImage(C11AW2NOV65)
                    setShowVoucher(<>
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
                else if (~["Justin Bieber X Vespa 150", "PRIMAVERA RED", "PRIMAVERA SEAN WOTHERSPOON"].indexOf(formData.model) < 0) {
                    setVoucherSet('set3');
                    setImage(C11AW3NOV65)
                    setShowVoucher(<>
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
                else if (formData.model === "PRIMAVERA S 150 CC Touring") {
                    setVoucherSet('set4');
                    setImage(C11AW4NOV65)
                    setShowVoucher(<>
                        &bull; Voucher 1,500 บาท<br />
                        &bull; Bumshaker Tote to go<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
                else if (formData.model === "PRIMAVERA 150 CC") {
                    setVoucherSet('set5');
                    setImage(C11AW5NOV65)
                    setShowVoucher(<>
                        &bull; Bumshaker Tote to go<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress229') {
                if (formData.model === "SEI GIORNI II EDITION 300 HPE") {
                    setVoucherSet('set3');
                    setImage(C04AW3APR66)
                    setShowVoucher(<>
                        &bull; ผ้ายางรองพื้น<br />
                        &bull; ชุดแต่ง Accessories<br />
                        &bull; Vespa Licensing<br />
                        &bull; Welcome Kit<br />
                    </>);
                }
                else if (formData.model === "GTS SUPER 150 CC") {
                    setVoucherSet('set2');
                    setImage(C04AW2APR66)
                    setShowVoucher(<>
                        &bull; Voucher 5,000 บาท<br />
                        &bull; ผ้ายางรองพื้น<br />
                        &bull; Vespa Licensing<br />
                        &bull; Welcome Kit<br />
                    </>);
                } else {
                    setVoucherSet('set1');
                    setImage(C04AW1APR66)
                    setShowVoucher(<>
                        &bull; Voucher 10,000 บาท<br />
                        &bull; ผ้ายางรองพื้น<br />
                        &bull; ชุดแต่ง Accessories<br />
                        &bull; Vespa Licensing<br />
                        &bull; Welcome Kit<br />
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress266') {
                if (~["LX 125 CC", "S 125 CC"].indexOf(formData.model) < 0) {
                    setVoucherSet('set1');
                    setImage(formData.model === "LX 125 CC" ? C02AW1SEP66 : C02AW12SEP66)
                    setShowVoucher(<>
                        &bull; Voucher 4,000 บาท<br />
                        &bull; Vespa Happy Rain<br />
                        &bull; Welcome Kit<br />
                    </>);
                } else if (~["LX 125 CC Touring", "S 125 CC Touring"].indexOf(formData.model) < 0) {
                    setVoucherSet('set2');
                    setImage(C02AW2SEP66)
                    setShowVoucher(<>
                        &bull; Voucher 4,000 บาท<br />
                        &bull; Touring Bag<br />
                        &bull; Vespa Happy Rain<br />
                        &bull; Welcome Kit<br />
                    </>);
                } else {
                    setVoucherSet('set3');
                    setImage(C02AW3SEP66)
                    setShowVoucher(<>
                        &bull; Voucher 4,000 บาท <br />
                        &bull; Sand & Sea Premium Set <br />
                        &bull; Vespa Happy Rain <br />
                        &bull; Welcome Kit
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress300') {
                if (['LX 125 CC', 'S 125 CC'].includes(formData.model)) {
                    setVoucherSet('set1');
                    setImage(formData.model === "LX 125 CC" ? C02AW1SEP66 : C02AW12SEP66)
                    setShowVoucher(<>
                        &bull; Voucher ตามมูลค่าที่กำหนด<br />
                        &bull; Welcome Kit
                    </>);
                } else if (['LX 125 CC Touring', 'S 125 CC Touring'].includes(formData.model)) {
                    setVoucherSet('set2');
                    setImage(C02AW2SEP66)
                    setShowVoucher(<>
                        &bull; Voucher ตามมูลค่าที่กำหนด<br />
                        &bull; Touring Bag<br />
                        &bull; Welcome Kit
                    </>);
                } else if (['LX 125 CC Sand Edition', 'S 125 CC Sea Edition'].includes(formData.model)) {
                    setVoucherSet('set3');
                    setImage(C02AW3SEP66)
                    setShowVoucher(<>
                        &bull; Voucher ตามมูลค่าที่กำหนด<br />
                        &bull; Sand & Sea Premium Set<br />
                        &bull; Welcome Kit
                    </>);
                } else {
                    setVoucherSet('set4');
                    setImage(C02AW4SEP66)
                    setShowVoucher(<>
                        &bull; Pink Rosa Premium Set<br />
                        &bull; Welcome Kit
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress249') {
                if (~["LX 125 CC", "S 125 CC"].indexOf(formData.model) < 0) {
                    setVoucherSet('set1');
                    setImage(C01AW1JUL66)
                    setShowVoucher(<>
                        &bull; Voucher 1,000 บาท<br />
                        &bull; Duo Set<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit<br />
                    </>);
                } else if (~["LX 125 CC Touring", "S 125 CC Touring"].indexOf(formData.model) < 0) {
                    setVoucherSet('set2');
                    setImage(C01AW2JUL66)
                    setShowVoucher(<>
                        &bull; Voucher 1,000 บาท<br />
                        &bull; Touring Bag<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit<br />
                    </>);
                }
                else {
                    setVoucherSet('set3');
                    setImage(C01AW3JUL66)
                    setShowVoucher(<>
                        &bull; Voucher 1,000 บาท<br />
                        &bull; Premium Set เฉพาะรุ่น<br />
                        &bull; Maintenance 1 ปี (3 ระยะ)<br />
                        &bull; Welcome Kit<br />
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress268') {
                if (formData.model === "PRIMAVERA COLOR VIBE") {
                    setVoucherSet('set2');
                    setImage(C04AW2SEP66)
                    setShowVoucher(<>
                        &bull; Voucher 4,000 บาท<br />
                        &bull; Bumshaker Hype Box และชุดแต่ง<br />
                        &bull; Vespa Happy Rain<br />
                        &bull; Welcome Kit<br />
                    </>);
                } else {
                    setVoucherSet('set1');
                    setImage(C04AW1SEP66)
                    setShowVoucher(<>
                        &bull; Voucher 4,000 บาท<br />
                        &bull; Vespa Happy Rain<br />
                        &bull; Welcome Kit<br />
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress181' || rewardType === 'RewardFillAddress188') {
                if (formData.model === 'GTS SUPER 150 CC') {
                    setVoucherSet('set2');
                    setImage(C09AW2DEC65)
                    setShowVoucher(<>
                        &bull; Voucher 2,000 บาท<br />
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                } else {
                    setVoucherSet('set1');
                    setImage(C09AW1DEC65)
                    setShowVoucher(<>
                        &bull; Voucher 2,000 บาท<br />
                        &bull; Bumshaker Mug & Cap<br />
                        &bull; ชุดแต่ง Accessories<br />
                        &bull; Vespa Welcome Kit<br />
                    </>);
                }
            }
            else if (rewardType === 'RewardFillAddress267') {
                setVoucherSet('set1');
                setImage(C03AW1SEP66)
                setShowVoucher(<>
                    &bull; Voucher 4,000 บาท<br />
                    &bull; Vespa Happy Rain<br />
                    &bull; Welcome Kit<br />
                </>);
            }
        }
        if (formData.model && formType === 'formType34') {
            if (rewardType === "RewardFillAddress284") {
                if (~['LX', "S"].indexOf(formData.model) < 0) {
                    setVoucherSet('set1');
                    setShowVoucher(<>
                        &bull; 1 ชิ้น มูลค่า 5,000 บาท
                    </>);
                    setImage(C06AW1SEP66);
                }
                else if (~['SPRINT', "PRIMAVERA"].indexOf(formData.model) < 0) {
                    setVoucherSet('set2');
                    setShowVoucher(<>
                        &bull; 1 ชิ้น มูลค่า 6,000 บาท
                    </>);
                    setImage(C06AW2SEP66);
                }
                else if (formData.model === "GTS (MY18/19)") {
                    setVoucherSet('set3');
                    setShowVoucher(<>
                        &bull; 1 ชิ้น มูลค่า 7,000 บาท
                    </>);
                    setImage(C06AW3SEP66);
                }
                else {
                    setVoucherSet('set4');
                    setShowVoucher(<>
                        &bull; 1 ชิ้น มูลค่า 8,000 บาท
                    </>);
                    setImage(C06AW4SEP66);
                }
            }
        }
    }, [formData.model])

    const setUsageAmount = () => {
        var list = [];
        if (formType === 'formType20') {
            list = [
                { key: 1, usageAmount: '3,000 - 5,999 บาท', couponValue: 'ส่วนลด 500 บาท' },
                { key: 2, usageAmount: '6,000 - 9,999 บาท', couponValue: 'ส่วนลด 1,500 บาท' },
                { key: 3, usageAmount: '10,000 บาท ขึ้นไป', couponValue: 'ส่วนลด 3,000 บาท' },
            ]
        }
        else if (formType === 'formType24') {
            list = [
                { key: 1, usageAmount: 'ยอดซื้อสินค้าอะไหล่ 946 บาทขึ้นไป', couponValue: 'VOUCHER ส่วนลด 200 บาท' },
                { key: 2, usageAmount: 'ยอดซื้อสินค้าอะไหล่ 1,946 บาทขึ้นไป', couponValue: 'VOUCHER ส่วนลด 500 บาท' },
            ]
        }
        else if (formType === 'formType36') {
            list = [
                { key: 1, usageAmount: 'ซื้อสินค้า 5,000 บาท ขึ้นไป', couponValue: 'รับ Voucher 1,000 บาท' },
                { key: 2, usageAmount: 'ซื้อสินค้า 15,000 บาท ขึ้นไป', couponValue: 'รับ Voucher 2,000 บาท' },
            ]
        }
        setUsageList(list);
    }

    const setMileag = () => {
        var list = [];
        if (formType === 'formType25') {
            list = [
                { key: 1, value: "1,000 กม.", label: "1,000 กม.", couponValue: 'VOUCHER 75 บาท' },
                { key: 2, value: "5,000 กม.", label: "5,000 กม.", couponValue: 'VOUCHER 75 บาท' },
                { key: 3, value: "10,000 กม.", label: "10,000 กม.", couponValue: 'VOUCHER 175 บาท' },
                { key: 4, value: "15,000 กม.", label: "15,000 กม.", couponValue: 'VOUCHER 75 บาท' },
                { key: 5, value: "20,000 กม.", label: "20,000 กม.", couponValue: 'VOUCHER 175 บาท' },
                { key: 6, value: "25,000 กม.", label: "25,000 กม.", couponValue: 'VOUCHER 75 บาท' },
                { key: 7, value: "30,000 กม.", label: "30,000 กม.", couponValue: 'VOUCHER 175 บาท' },
            ]
        }
        setMileagList(list);
    }

    const setSparepart = () => {
        var list = [];

        if (formType === 'formType27') {
            if (rewardType === 'RewardFillAddress247') {
                list = [
                    {
                        key: 1, value: "น้ำมันเครื่อง",
                        couponValue: "น้ำมันเครื่อง 1 ขวด หรือ 0.7 ลิตร"
                    },
                ]
            } else if (rewardType === 'RewardFillAddress274') {
                list = [
                    {
                        key: 1, value: "น้ำมันเครื่อง, น้ำมันเฟืองท้าย, กรองน้ำมันเครื่อง และโอริงกรองน้ำมันเครื่อง",
                        couponValue: "ส่วนลด 300 บาท"
                    },
                ]
            }

            else if (rewardType === 'RewardFillAddress275') {
                list = [
                    {
                        key: 1, value: "สายเบรกหลัง, สายคันเร่ง, สายไมล์",
                        couponValue: "ส่วนลด 50% (เฉพาะรุ่นที่ร่วมรายการ)"
                    },
                ]
            }
            else {
                list = [
                    {
                        key: 1, value: "สายพาน, ไส้กรองอากาศ, ตุ้มแรงเหวี่ยง, ตัวล็อคเม็ดตุ้มแรงเหวี่ยง และหัวเทียน",
                        couponValue: (
                            <>
                                ไส้กรองอากาศ <br />
                                &bull; หัวเทียน <br />
                                &bull; ตุ้มแรงเหวี่ยง
                            </>
                        )
                    },
                ]
            }
        } else {
            list = [
                { key: 1, value: "สายพาน กรองอากาศ ตุ้มแรงเหวี่ยง ตัวล็อคเม็ดตุ้มแรงเหวี่ยง และหัวเทียน", couponValue: '' },
            ]
            const expensesList = [
                { key: 1, value: "2,000 - 2,999 บาท", couponValue: 'ส่วนลด 700 บาท' },
                { key: 2, value: "3,000 บาท ขึ้นไป", couponValue: 'ส่วนลด 1,000 บาท' },
            ]
            setExpenses(expensesList)
        }
        setSparepartList(list);
    }

    useEffect(() => {
        if (formType === 'formType27' && rewardType === 'RewardFillAddress248') {
            if (["GTS", "SEI GIORNI"].includes(formData.model) && formData.size === "300 CC") {
                setFormData({
                    ...formData,
                    couponValue: <>
                        ไส้กรองอากาศ <br />
                        &bull; ตุ้มแรงเหวี่ยง
                    </>
                })
                setImage(C08AW1MAY66)
            } else {
                const find = sparepartList.find(f => f.value === formData.sparepart)
                console.log(find);
                setFormData({
                    ...formData,
                    couponValue: find?.couponValue || ''
                })
                setImage(C08AW1MAY66)
            }
        } else {
            const find = sparepartList.find(f => f.value === formData.sparepart)
            setFormData({
                ...formData,
                couponValue: find?.couponValue || ''
            })
            if (find?.value === "น้ำมันเครื่อง, น้ำมันเฟืองท้าย, กรองน้ำมันเครื่อง และโอริงกรองน้ำมันเครื่อง") {
                setImage(C01AW1OCT66)
            } else if (find?.value === "สายเบรกหลัง, สายคันเร่ง, สายไมล์") {
                setImage(C02AW1OCT66)

            }

        }

    }, [formData.size, formData.model, formData.sparepart])

    // useEffect(() => {
    //     if (formData.sparepart && formType !== "formType32" && rewardType !== 'RewardFillAddress233') {
    //         var find = sparepartList.find(f => f.value === formData.sparepart)
    //         setFormData({
    //             ...formData,
    //             couponValue: find?.couponValue || ''
    //         })
    //         setImage(C06AW1APR66)
    //     }
    // }, [formData.sparepart])
    useEffect(() => {
        if (rewardType === 'RewardFillAddress297') {
            setImage(M5);
        }
    }, [formData.changedItems])

    useEffect(() => {
        if (formData.size) {
            if (formType === 'formType28') {
                if (rewardType === 'RewardFillAddress50') {
                    setFormData({
                        ...formData,
                        couponValue: '999 บาท'
                    })
                    setImage(Plus1Img);
                } else if (rewardType === 'RewardFillAddress51') {
                    if (formData.size === '300 CC') {
                        setFormData({
                            ...formData,
                            couponValue: '2,899 บาท'
                        })
                        setImage(Plus22Img);
                    } else {
                        setFormData({
                            ...formData,
                            couponValue: '2,199 บาท'
                        })
                        setImage(Plus21Img);
                    }
                }
            }

            if (formType === 'formType30') {
                if (rewardType === 'RewardFillAddress93' || rewardType === 'RewardFillAddress98' || rewardType === 'RewardFillAddress146') {
                    if (formData.size === '300 CC') {
                        setFormData({
                            ...formData,
                            couponValue: '-'
                        })
                        setImage(Free21Img);
                        setShowVoucher(<>
                            &bull; ส่วนลดสายพาน 50%<br />
                        </>)
                    } else {
                        setFormData({
                            ...formData,
                            couponValue: '-'
                        })
                        setImage(Free21Img);
                        setShowVoucher(<>
                            &bull; ส่วนลดสายพาน 50%<br />
                        </>)
                    }
                }
                if (rewardType === 'RewardFillAddress94' || rewardType === 'RewardFillAddress99' || rewardType === 'RewardFillAddress160' || rewardType === 'RewardFillAddress171' || rewardType === 'RewardFillAddress183') {
                    if (formData.size === '125 CC') {
                        setFormData({
                            ...formData,
                            couponValue: 'ส่วนลดน้ำมันเครื่อง 50%'
                        })
                    } else {
                        setFormData({
                            ...formData,
                            couponValue: 'ส่วนลดน้ำมันเครื่อง 50%'
                        })
                    }
                    setShowVoucher(<>
                        &bull; ส่วนลดน้ำมันเครื่อง 50%<br />
                    </>)
                    setImage(C11AW1DEC65);
                }
                else if (rewardType === 'RewardFillAddress161' || rewardType === 'RewardFillAddress172' || rewardType === 'RewardFillAddress184') {
                    if (formData.size === '300 CC') {
                        setFormData({
                            ...formData,
                            couponValue: 'ส่วนลดอะไหล่ทั้งชุด 50% (เฉพาะรุ่นที่ร่วมรายการ)'
                        })
                        setShowVoucher(<>
                            &bull; ส่วนลดอะไหล่ทั้งชุด 50% (เฉพาะรุ่นที่ร่วมรายการ)<br />
                        </>)
                        setImage(C12AW1DEC65);
                    } else {
                        setFormData({
                            ...formData,
                            couponValue: 'ส่วนลดอะไหล่ทั้งชุด 50%'
                        })
                        setShowVoucher(<>
                            &bull; ส่วนลดอะไหล่ทั้งชุด 50%<br />
                        </>)
                        setImage(C12AW1DEC65);
                    }
                }
            }
        }
    }, [formData.size])

    useEffect(() => {
        if (formData.product) {
            if (formType === 'formType31') {
                setFormData({
                    ...formData,
                    couponValue: '-'
                })
                setShowVoucher(<>
                    &bull; ส่วนลด 10%
                </>)
                if (formData.product === 'Vespa Accessories') {
                    setImage(C05AW1JUL66);
                } else if (formData.product === 'Vespa Accessories Package') {
                    setImage(C05AW2JUL66);
                } else {
                    setImage(C05AW3JUL66);
                }
            } else if (formType === 'formType33') {
                setFormData({
                    ...formData,
                    couponValue: '-'
                })
                setShowVoucher(<>
                    &bull; ส่วนลด 30%
                </>)
                setImage(C08AW1APR66);
            }
            else if (formType === 'formType35') {
                setFormData({
                    ...formData,
                    couponValue: '-'
                })

                if (formData.product === 'Vespa Accessories') {
                    setImage(M1);
                    setShowVoucher(<>
                        &bull; รับ Voucher 1,000 บาท
                    </>)
                } else if (formData.product === "Vespa Licensing") {
                    setImage(M2);
                    setShowVoucher(<>
                        &bull; รับ Voucher 1,000 บาท
                    </>)
                } else if (formData.product === "Bumshaker") {
                    setImage(M3);
                    setShowVoucher(<>
                        &bull; รับ Voucher 1,000 บาท
                    </>)
                } else {
                    setImage(M4);
                    setShowVoucher(<>
                        &bull; รับ Voucher 1,000 บาท
                    </>)
                }
            }
            else if (formType === 'formType10') {
                setFormData({
                    ...formData,
                    couponValue: '-'
                })


                if (formData.product === 'กันชนบังโคลนหน้า / ผ้ายางรองพื้น') {
                    setImage(C06AW1JUL66);
                    setShowVoucher(<>
                        &bull; ส่วนลด 300 บาท
                    </>)
                } else if (formData.product === 'กันล้มหน้า-หลัง / ตะแกรงหน้า-หลัง / ตะแกรง Topbox') {
                    setImage(C06AW2JUL66);
                    setShowVoucher(<>
                        &bull; ส่วนลด 1,000 บาท
                    </>)
                } else if (formData.product === "Vespa Accessories แบบชิ้น / Package") {
                    setImage(C01AW1SEP66);
                    setShowVoucher(<>
                        &bull; ส่วนลด 1,000 บาท
                    </>)
                } else if (formData.product === "ชุดพักเท้าหลัง และชุดตะขอแขวน อย่างละ 1 ชิ้น (สำหรับรุ่น Sprint/Primavera)") {
                    setImage(C01AW2SEP66);
                    setShowVoucher(<>
                        &bull; ส่วนลด 2,000 บาท
                    </>)
                }
                else if (formData.product === "ชุด White Collection (Top Box, ตะแกรงยึด Top Box และพนักพิง) อย่างละ 1 ชิ้น (สำหรับรุ่น Sprint/Primavera)") {
                    setImage(C01AW3SEP66);
                    setShowVoucher(<>
                        &bull; ส่วนลด 5,000 บาท
                    </>)
                }
                else if (formData.product === "Vespa Accessories") {
                    setImage(C07AW1SEP66);
                    setShowVoucher(<>
                        &bull; ส่วนลด 10%
                    </>)
                }
                else if (formData.product === "Vespa Accessories Package") {
                    setImage(C07AW2SEP66);
                    setShowVoucher(<>
                        &bull; ส่วนลด 10%
                    </>)
                }
                else if (formData.product === "หมวก Bumshaker") {
                    setImage(C07AW3SEP66);
                    setShowVoucher(<>
                        &bull; ส่วนลด 10%
                    </>)
                }
                else {
                    setImage(C06AW3JUL66);
                    setShowVoucher(<>
                        &bull; ส่วนลด 2,000 บาท
                    </>)
                }
            }
        }
    }, [formData.product])

    useEffect(() => {
        if (formData.usageAmount) {
            if (formData.usageAmount === "3,000 - 5,999 บาท") {
                setImage(C09AW1JAN66);
            } else if (formData.usageAmount === "6,000 - 9,999 บาท") {
                setImage(C09AW2JAN66);
            }
            else if (formData.usageAmount === "ซื้อสินค้า 5,000 บาท ขึ้นไป") {
                setImage(J5);
            }
            else if (formData.usageAmount === "ซื้อสินค้า 15,000 บาท ขึ้นไป") {
                setImage(J6);
            } else {
                setImage(C09AW3JAN66);
            }
            console.log(formData.usageAmount);
            var find = usegeList.find(f => f.usageAmount === formData.usageAmount)
            setFormData({
                ...formData,
                couponValue: find?.couponValue || ''
            })
        }
        else {
            setFormData({
                ...formData,
                couponValue: ''
            })
        }
    }, [formData.usageAmount])

    useEffect(() => {
        if (formType === 'formType32') {
            const e = expenses.find(f => f.value === formData.expenses)
            setFormData({
                ...formData,
                couponValue: e?.couponValue || ''
            })
            if (formData.expenses === "2,000 - 2,999 บาท") {
                setImage(C08AW1MAR66);
            } else {
                setImage(C08AW2MAR66);
            }
        }
    }, [formData.expenses])

    useEffect(() => {
        if (formData.mileageCheckup) {
            var find = mileagList.find(f => f.value === formData.mileageCheckup)
            setFormData({
                ...formData,
                couponValue: find?.couponValue || ''
            })

            if (find?.couponValue === 'VOUCHER 75 บาท') {
                setImage(Oct21Discount75Img);
                setVoucherSet('Voucher 75');
            }
            else {
                setImage(Oct21Discount175Img);
                setVoucherSet('Voucher 175');
            }
        }
        else {
            setFormData({
                ...formData,
                couponValue: ''
            })
        }
    }, [formData.mileageCheckup])

    let [checkEngineNumberFormType29, setCheckEnNo29] = useState(true);
    let [errorEngineNumber, setErrorEnNo29] = useState('');
    useEffect(() => {
        if (formType === 'formType29') {
            if ((formData.engineNumber || '').length === 12) {
                let apiPath = API.CHECK_ENGINE_NUMBER;
                let param = {
                    accesstoken: token,
                    enginenumber: formData.engineNumber,
                }
                const encodedData = encode(param);

                (async () => {
                    const response = await axios.post(apiPath, encodedData);
                    if (response?.data?.isExist === "False") {
                        setCheckEnNo29(false);
                        setErrorEnNo29('เลขเครื่องยนต์ไม่ถูกต้อง');
                    } else if (response?.data?.isExist === "True") {
                        if (response?.data?.status === "notuse") {
                            setCheckEnNo29(true);
                        } else {
                            setCheckEnNo29(false);
                            setErrorEnNo29('เลขเครื่องยนต์นี้ถูกใช้งานแล้ว');
                        }
                    } else {
                        setCheckEnNo29(false);
                        setErrorEnNo29('เลขเครื่องยนต์ไม่ถูกต้อง');;
                    }
                })();
            }
        }
    }, [formData.engineNumber])

    /*** ---------------------- onchange ---------------------- ***/

    const onChangeInput = (e) => {
        let { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
        setFormBlur({
            ...formBlur,
            [name]: true
        })
    }

    const onBlur = (e) => {
        setFormBlur({
            ...formBlur,
            [e.target.name]: true
        })
    }

    useEffect(() => {
        setIsSubmit(false);
        if (formType === 'formType1') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.addressDetail
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType3') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.addressDetail
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType5' || formType === 'formType10' || formType === 'formType19') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType20') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model
                && formData.usageAmount && formData.couponValue
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType36') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.product
                && formData.usageAmount && formData.couponValue
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType21') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && formData.purchasedDate && formData.purchasedMonth && formData.purchasedYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType23') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && formData.purchasedDate && formData.purchasedMonth && formData.purchasedYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType24') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.size
                && formData.usageAmount && formData.couponValue
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType25') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.size
                && formData.mileageCheckup && formData.couponValue
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType26') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType31') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.product
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType33') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.product
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType34') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.product
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType37') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.package
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear

            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType38') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.changedItems
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear

            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType35') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.product
              && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType27') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.size
                && formData.sparepart && formData.couponValue
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType32') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.size
                && formData.sparepart && formData.couponValue && formData.expenses
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType28') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.size
                && formData.couponValue
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
        else if (formType === 'formType30') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model && formData.size
                && formData.couponValue
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && (formData.bodyNumber && formData.bodyNumber.length === 17 && (/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)))
                && formData.serviceDate && formData.serviceMonth && formData.serviceYear
            ) {
                setIsSubmit(true);
            }
        }
    }, [formData])

    useEffect(() => {
        if (formType === 'formType29') {
            if (
                formData.firstname && formData.lastname
                && (formData.mobile && formData.mobile.length === 10 && !isNaN(formData.mobile))
                && formData.targetBranch && formData.model
                && (formData.engineNumber && formData.engineNumber.length === 12 && (/^[a-zA-Z0-9]+$/.test(formData.engineNumber)))
                && formData.purchasedDate && formData.purchasedMonth && formData.purchasedYear
                && checkEngineNumberFormType29
            ) {
                setIsSubmit(true);
            } else {
                setIsSubmit(false);
            }
        }
    }, [formData, checkEngineNumberFormType29])

    useEffect(() => {
        if (formData.serviceDate && formData.serviceMonth && formData.serviceYear) {
            const serviceDate = `${zeroPadding(formData.serviceDate, 2)}/${zeroPadding(formData.serviceMonth, 2)}/${formData.serviceYear}`
            if (!moment(serviceDate, "DD/MM/YYYY", true).isValid()) {
                setNotValidServiceDate(true);
            }
            else {
                setNotValidServiceDate(false);
            }
        }
        else {
            setNotValidServiceDate(false);
        }
    }, [formData])

    useEffect(() => {
        if (formData.purchasedDate && formData.purchasedMonth && formData.purchasedYear) {
            const purchasedDate = `${zeroPadding(formData.purchasedDate, 2)}/${zeroPadding(formData.purchasedMonth, 2)}/${formData.purchasedYear}`
            if (!moment(purchasedDate, "DD/MM/YYYY", true).isValid()) {
                setNotValidPurchaseDate(true);
            }
            else {
                setNotValidPurchaseDate(false);
            }
        }
        else {
            setNotValidPurchaseDate(false);
        }
    }, [formData])

    /*** ---------------------- submit ---------------------- ***/
    const usedCoupon = () => {

        if (formData.serviceDate && formData.serviceMonth && formData.serviceYear) {
            const serviceDate = `${zeroPadding(formData.serviceDate, 2)}/${zeroPadding(formData.serviceMonth, 2)}/${formData.serviceYear}`
            if (!moment(serviceDate, "DD/MM/YYYY", true).isValid()) {
                alert('วันที่ใช้บริการไม่ถูกต้อง');
            }
            else {
                setShowForm(true);
            }
        }
        else if (formData.purchasedDate && formData.purchasedMonth && formData.purchasedYear) {
            const purchasedDate = `${zeroPadding(formData.purchasedDate, 2)}/${zeroPadding(formData.purchasedMonth, 2)}/${formData.purchasedYear}`
            if (!moment(purchasedDate, "DD/MM/YYYY", true).isValid()) {
                alert('วันที่ออกรถไม่ถูกต้อง');
            }
            else {
                setShowForm(true);
            }
        }
        else {
            setShowForm(true);
        }
    }

    const backToEdit = () => {
        setShowForm(false);
    }

    const onSubmit = async () => {
        // setLoading(true);

        var param = {
            accesstoken: token,
            lineuserid: profile.userId,
            // dealerid: branchId,
            code: couponCode,
            firstname: formData?.firstname,
            lastname: formData?.lastname,
            mobile: formData?.mobile,
            ...(isDealer && { dealerid: branchId })
        };

        var apiPath = '';

        var filterBranch = branchListAll.filter(f => f.facadeth === formData.targetBranch);
        var _branchId = "000";
        if (filterBranch.length) {
            _branchId = filterBranch[0].id
        }

        if (formType === 'formType1') {
            apiPath = API.REDEEM_RANDOM_COUPON;
            param = {
                ...param,
                enginenumber: formData?.engineNumber,
                address: formData?.addressDetail
            }
        }
        else if (formType === 'formType5') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                // brandmodel: formData.model === 'GTS SUPER ABS (MY 18)' ? 'GTS SUPER ABS 300 (MY 18)' : formData.model === 'GTS SUPER SPORT ABS (MY 19)' ? 'GTS SUPER SPORT 300 ABS (MY 19)' : '',
                // brandmodeldetail1: "GTS",
                // brandmodeldetail2: formData.size,
                brandmodel: formData.model,
                brandmodeldetail1: "GTS",
                brandmodeldetail2: "300 CC",
                enginenumber: formData.engineNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`,
                param1: "package",
                param1value: voucherSet,
            }
        }
        else if (formType === 'formType10') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            console.log(showVoucher);
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model,
                chassisnumber: formData.bodyNumber,
                param1: "ของรางวัล",
                param1value: showVoucher.props.children.replace(/[!@#$^&*•]/g, "").toString(),
                param2: "สินค้าที่ซื้อ",
                param2value: formData.product,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`,
            }
        }
        else if (formType === 'formType19') {
            apiPath = API.ACTIVATE_COUPON_19_JUL;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                enginenumber: formData.engineNumber,
                purchasedate: `${formData.purchasedYear}-${zeroPadding(formData.purchasedMonth, 2)}-${zeroPadding(formData.purchasedDate, 2)}`,
            }
        }
        else if (formType === 'formType20') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                param1: "usage",
                param1value: formData.usageAmount,
                param2: "reward",
                param2value: formData.couponValue,
                enginenumber: formData.engineNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`
            }
        }
        else if (formType === 'formType36') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail2: formData.size,
                param1: "Coupon",
                param1value: formData.couponValue,
                param2: "product",
                param2value: `${formData.product} - ${formData.usageAmount}`,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`
            }
        }
        else if (formType === 'formType21') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model,
                brandmodeldetail1: formData.model,
                enginenumber: formData.engineNumber,
                purchasedate: `${formData.purchasedYear}-${zeroPadding(formData.purchasedMonth, 2)}-${zeroPadding(formData.purchasedDate, 2)}`,
            }
        }
        else if (formType === 'formType23') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model,
                brandmodeldetail1: formData.model,
                enginenumber: formData.engineNumber,
                purchasedate: `${formData.purchasedYear}-${zeroPadding(formData.purchasedMonth, 2)}-${zeroPadding(formData.purchasedDate, 2)}`,
            }
        }
        else if (formType === 'formType24') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                param1: "usage",
                param1value: formData.usageAmount,
                param2: "reward",
                param2value: formData.couponValue,

                enginenumber: formData.engineNumber,
                chassisnumber: formData.bodyNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`
            }
        }
        else if (formType === 'formType25') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                param1: "Voucher",
                param1value: voucherSet,
                param2: "Mileage",
                param2value: formData.mileageCheckup,

                enginenumber: formData.engineNumber,
                chassisnumber: formData.bodyNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`
            }
        }
        else if (formType === 'formType26') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;

            var modelSize = modelListAll.find(f => f.titleen === formData.model);
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model,
                brandmodeldetail1: modelSize?.titlesub1 || '',
                brandmodeldetail2: modelSize?.titlesub2 || '',
                // enginenumber: formData.engineNumber,
                chassisnumber: formData.bodyNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`,
                param1: "package",
                param1value: voucherSet,
            }
        }
        else if (formType === 'formType31') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;

            var modelSize = modelListAll.find(f => f.titleen === formData.model);
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model,
                brandmodeldetail1: modelSize?.titlesub1 || '',
                brandmodeldetail2: modelSize?.titlesub2 || '',
                enginenumber: formData.engineNumber,
                chassisnumber: formData.bodyNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`,
                param1: "product",
                param1value: formData.product,
            }
        }
        else if (formType === 'formType33') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;

            var modelSize = modelListAll.find(f => f.titleen === formData.model);
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model,
                brandmodeldetail1: modelSize?.titlesub1 || '',
                brandmodeldetail2: modelSize?.titlesub2 || '',
                enginenumber: formData.engineNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`,
                param1: "product",
                param1value: formData.product,
                // param2: "color",
                // param2value: formData.color,
            }
        }
        else if (formType === 'formType34') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;

            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model,
                brandmodeldetail1: modelSize?.titlesub1 || '',
                brandmodeldetail2: modelSize?.titlesub2 || '',
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`,
                param1: "Coupon",
                param1value: showVoucher.props.children.replace(/[!@#$^&*•]/g, "").toString(),
                param2: "product",
                param2value: formData.product,
            }
        }
        else if (formType === 'formType37') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;

            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model,
                brandmodeldetail1: modelSize?.titlesub1 || '',
                brandmodeldetail2: modelSize?.titlesub2 || '',
                chassisnumber: formData.bodyNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`,
                param1: "Coupon",
                param1value: showVoucher.props.children.replace(/[!@#$^&*•]/g, "").toString(),
                param2: "package",
                param2value: formData.package,
            }
        }
        else if (formType === 'formType38') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;

            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model,
                brandmodeldetail1: modelSize?.titlesub1 || '',
                brandmodeldetail2: modelSize?.titlesub2 || '',
                chassisnumber: formData.bodyNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`,
                param1: "Coupon",
                param1value: showVoucher.props.children.replace(/[!@#$^&*•]/g, "").toString(),
                param2: "รายการที่เปลี่ยน",
                param2value: formData.changedItems,
            }
        }
        else if (formType === 'formType35') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;

            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodeldetail1: modelSize?.titlesub1 || '',
                brandmodeldetail2: modelSize?.titlesub2 || '',
                chassisnumber: formData.bodyNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`,
                param1: "Coupon",
                param1value: showVoucher.props.children.replace(/[!@#$^&*•]/g, "").toString(),
                param2: "product",
                param2value: formData.product,
            }
        }
        else if (formType === 'formType27') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                param1: "ของรางวัล",
                // param1value: formData.couponValue.props.children.map((i) => typeof i === "string" && i.replace(/[!@#$%^&*•]/g, "")).filter(Boolean).toString(),
                param1value: typeof formData.couponValue === "string" ? formData.couponValue : formData.couponValue.props.children.map((i) => typeof i === "string" && i.replace(/[!@#$%^&*•]/g, "")).filter(Boolean).toString(),
                param2: "รายการอะไหล่",
                param2value: formData.sparepart,

                enginenumber: formData.engineNumber,
                chassisnumber: formData.bodyNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`
            }
        }
        else if (formType === 'formType32') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                param1: "expenses",
                param1value: formData.expenses,
                param2: "reward",
                param2value: formData.couponValue,

                enginenumber: formData.engineNumber,
                chassisnumber: formData.bodyNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`
            }
        }
        else if (formType === 'formType28') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                param2: "reward",
                param2value: formData.couponValue,

                enginenumber: formData.engineNumber,
                chassisnumber: formData.bodyNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`
            }
        }
        else if (formType === 'formType29') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                enginenumber: formData.engineNumber,
                purchasedate: `${formData.purchasedYear}-${zeroPadding(formData.purchasedMonth, 2)}-${zeroPadding(formData.purchasedDate, 2)}`,
            }
        }
        else if (formType === 'formType30') {
            apiPath = API.REDEEM_COUPON_FILL_ADDRESS;
            param = {
                ...param,
                branchid: _branchId,
                branchothername: formData.targetBranch,
                brandmodel: formData.model + " " + formData.size,
                brandmodeldetail1: formData.model,
                brandmodeldetail2: formData.size,
                param2: "reward",
                param2value: formData.couponValue,

                enginenumber: formData.engineNumber,
                chassisnumber: formData.bodyNumber,
                couponusedate: `${formData.serviceYear}-${zeroPadding(formData.serviceMonth, 2)}-${zeroPadding(formData.serviceDate, 2)}`
            }
        }

        const encodedData = encode(param);
        (async () => {
            const response = await axios.post(apiPath, encodedData);
            if (response?.data?.result === "complete") {
                // alert("complete!!");
                window.location = `/my-used-coupon?branchId=${branchId}`
            }
            else {
                setLoading(false);
                if (response?.data?.detail === 'FULLY_ACTIVATE') {
                    alert("ขออภัย ใช้สิทธิ์ครบแล้ว");
                }
                else if (response?.data?.detail === 'Engine_NotFound') {
                    alert("ขออภัย รุ่นและสีดังกล่าวไม่สามารถใช้คูปองนี้ได้");
                }
                else if (response?.data?.detail === 'Engine_Used') {
                    alert("ขออภัย เลขเครื่องยนต์นี้ถูกใช้งานแล้ว");
                }
                else if (response?.data?.detail === 'CouponCode_Expired') {
                    alert("ขออภัย คูปองหมดอายุแล้ว");
                }
                else {
                    alert(response?.data?.detail || 'ขออภัย ไม่สามารถกดใช้คูปองได้');
                }
            }
        })();
    }

    return (<>
        {!isShow ? (
            <AppBarNone />
        ) :
            <div className={classes.container}>
                <AppBar />

                <div className={classes.content}>
                    <div className={classes.wrapper}>
                        <div className={classes.headingPath}>
                            <a href={`/my-new-coupon?branchId=${branchId}`} className={classes.headingBack}>
                            </a>
                            <div className={classes.headingText}>
                                {data?.titleth || ''}
                            </div>
                        </div>

                        {!showForm && (
                            <div className={classes.coupon}>
                                <div className={classes.coupons}>
                                    <div className={classes.couponImageWrap}>
                                        <img className="coupon__image" src={image || data?.assetrectangle} alt="coupon" />

                                        <div className={classes.couponNumberWrap}>
                                            <div className={classes.couponNumber}>
                                                {`No. ${data?.code}`}
                                            </div>
                                        </div>
                                        <div className={classes.couponFooter}>
                                            <div className={classes.couponContentFooter}> {`หมดเขต ${humanExpireTime(data.expiretime)}`}</div>
                                        </div>
                                    </div>

                                    <form className={classes.form3}>
                                        {true && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    ชื่อ-นามสกุล
                                                </label>
                                                <div>
                                                    <input className={classes.formFieldInput}
                                                        placeholder="ชื่อ-นามสกุล"
                                                        type="text"
                                                        name="firstName"
                                                        value={formData?.firstname + " " + formData?.lastname}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {true && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    เบอร์โทรศัพท์
                                                </label>
                                                <div>
                                                    <input className={classes.formFieldInput}
                                                        placeholder="เบอร์โทรศัพท์"
                                                        type="text"
                                                        name="mobile"
                                                        value={formData?.mobile}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                        maxLength={10}
                                                    />
                                                </div>
                                                {!formData?.mobile && formBlur?.mobile && (
                                                    <div className={classes.formFieldError}>กรุณากรอกข้อมูล</div>
                                                )}
                                                {formData?.mobile && (
                                                    <>
                                                        {isNaN(formData?.mobile) ? (
                                                            <div className={classes.formFieldError}>กรอกเป็นตัวเลขเท่านั้น</div>
                                                        ) : (formData?.mobile || '').length < 10 ? (
                                                            <div className={classes.formFieldError}>กรอกตัวเลข 10 หลัก</div>
                                                        ) : null}
                                                    </>
                                                )}
                                            </div>
                                        )}

                                        {~['formType5', 'formType20', 'formType21',
                                            'formType23', 'formType24', 'formType25',
                                            'formType26', 'formType27', 'formType10', 'formType19'
                                            , 'formType28', 'formType29', 'formType30', 'formType31', 'formType32', 'formType33', 'formType34', 'formType35', 'formType36', 'formType37', 'formType38'
                                        ].indexOf(formType) < 0 && (
                                                <div className={classes.formItem}>
                                                    <label className={classes.formLabel}>
                                                        โชว์รูม/ศูนย์บริการ
                                                    </label>
                                                    <div>
                                                        <input
                                                            className={classes.formFieldInput}
                                                            placeholder="ระบุสาขา"
                                                            type="text"
                                                            name="targetBranch"
                                                            value={formData?.targetBranch}
                                                            onChange={onChangeInput}
                                                            onBlur={onBlur}
                                                            list="branchlist"
                                                        />
                                                        {formData.targetBranch.length >= 3 && (
                                                            <datalist id="branchlist">
                                                                {branchList.map(m => (
                                                                    <option value={m} key={m}>{m}</option>
                                                                ))}
                                                            </datalist>
                                                        )}
                                                    </div>
                                                    {!formData?.targetBranch && formBlur?.targetBranch && (
                                                        <div className={classes.formFieldError}>กรุณากรอกสาขา</div>
                                                    )}
                                                </div>
                                            )}

                                        {~["formType35"].indexOf(formType) < 0 && (
                                          <div className={classes.formItem}>
                                              <label className={classes.formLabel}>
                                                  เลขถังรถ
                                              </label>
                                              <div>
                                                  <input className={classes.formFieldInput}
                                                         placeholder="เลขถังรถ"
                                                         type="text"
                                                         name="bodyNumber"
                                                         value={formData?.bodyNumber}
                                                         onChange={onChangeInput}
                                                         onBlur={onBlur}
                                                         maxLength={17}
                                                         disabled
                                                  />
                                              </div>
                                          </div>
                                        )}

                                        {~['formType37'].indexOf(formType) < 0 && (
                                          <div className={classes.formItem}>
                                              <label className={classes.formLabel}>
                                                  แพ็กเกจที่ซื้อ
                                              </label>
                                              <div>
                                                  <select
                                                    className={formData.package ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                    name="package"
                                                    value={formData.package}
                                                    onChange={onChangeInput}
                                                    onBlur={onBlur}
                                                  >
                                                      <option value="">เลือกแพ็กเกจที่ซื้อ</option>
                                                      {["Vespa Maintenance Package"].map(m => (
                                                        <option value={m} key={m}>{m}</option>
                                                      ))}
                                                  </select>
                                              </div>
                                              {!formData?.package && formBlur?.package && (
                                                <div className={classes.formFieldError}>กรุณาเลือกแพ็กเกจที่ซื้อ</div>
                                              )}
                                          </div>
                                        )}

                                        {~['formType5', 'formType10', 'formType20', 'formType21',
                                            'formType23', 'formType24', 'formType25',
                                            'formType26', 'formType27', 'formType19'
                                            , 'formType28', 'formType29', 'formType30', 'formType31', 'formType32', 'formType32', 'formType33', 'formType34', 'formType37', 'formType38'].indexOf(formType) < 0 && (
                                          <div className={classes.formItem}>
                                              <label className={classes.formLabel}>
                                                  รุ่นรถ
                                              </label>
                                              <div>
                                                  <select
                                                            className={formData.model ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                            name="model"
                                                            value={formData.model}
                                                            onChange={onChangeInput}
                                                            onBlur={onBlur}
                                                        >
                                                            <option value="">เลือกรุ่น</option>
                                                            {modelList.map(m => (
                                                                <option value={m} key={m}>{m}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {!formData?.model && formBlur?.model && (
                                                        <div className={classes.formFieldError}>กรุณาเลือกรุ่น</div>
                                                    )}
                                                </div>
                                            )}

                                        {~['formType34'].indexOf(formType) < 0 && formData.model && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    ของแถมที่ได้รับ
                                                </label>
                                                <div className={classes.redeemDetail2}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType37'].indexOf(formType) < 0 && formData.model && formData.package && (
                                          <div className={classes.formItem}>
                                              <label className={classes.formLabel}>
                                                  ของแถมที่ได้รับ
                                              </label>
                                              <div className={classes.redeemDetail2}>
                                                  {showVoucher}
                                              </div>
                                          </div>
                                        )}

                                        {~['formType38'].indexOf(formType) < 0 && (
                                          <div className={classes.formItem}>
                                              <label className={classes.formLabel}>
                                                  รายการที่เปลี่ยน
                                              </label>
                                              <div>
                                                  <select
                                                    className={formData.changedItems ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                    name="changedItems"
                                                    value={formData.changedItems}
                                                    onChange={onChangeInput}
                                                    onBlur={onBlur}
                                                  >
                                                      <option value="">เลือกรายการที่เปลี่ยน</option>
                                                      {["สายพาน, เม็ดตุ้ม, ตัวล็อกเม็ดตุ้ม"].map(m => (
                                                        <option value={m} key={m}>{m}</option>
                                                      ))}
                                                  </select>
                                              </div>
                                              {!formData?.changedItems && formBlur?.changedItems && (
                                                <div className={classes.formFieldError}>กรุณาเลือกรายการที่เปลี่ยน</div>
                                              )}
                                          </div>
                                        )}

                                        {~['formType38'].indexOf(formType) < 0 && formData.model && formData.changedItems && (
                                          <div className={classes.formItem}>
                                              <label className={classes.formLabel}>
                                                  ของแถมที่ได้รับ
                                              </label>
                                              <div className={classes.redeemDetail2}>
                                                  {showVoucher}
                                              </div>
                                          </div>
                                        )}

                                        {~['formType31', 'formType33', 'formType10'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    สินค้าที่ซื้อ
                                                </label>
                                                <div>
                                                    <select
                                                        className={formData.product ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                        name="product"
                                                        value={formData.product}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                    >

                                                        <option value="">เลือกสินค้าที่ซื้อ</option>
                                                        {/* ["กระจกบังลมสั้น + ตะแกรงหน้า", "กระจกบังลมสั้น + ตะแกรงหลัง", "กันชนบังโคลนหน้า + กันล้มหน้า", "กันชนบังโคลนหน้า + กันล้มหลัง", "กันล้มหน้า + กันล้มหลัง", "กันล้มหลัง + ตะแกรงหลัง", "ตะแกรงหน้า + ตะแกรงหลัง", "ตะแกรง + Top box",] */}
                                                        {(formType === "formType31" || formType === "formType10" ? ["Vespa Accessories", "Vespa Accessories Package", "หมวก Bumshaker"] :
                                                            rewardType === "RewardFillAddress265" ? ["Vespa Accessories แบบชิ้น / Package", "ชุดพักเท้าหลัง และชุดตะขอแขวน อย่างละ 1 ชิ้น (สำหรับรุ่น Sprint/Primavera)", "ชุด White Collection (Top Box, ตะแกรงยึด Top Box และพนักพิง) อย่างละ 1 ชิ้น (สำหรับรุ่น Sprint/Primavera)"] : ["กันชนบังโคลนหน้า / ผ้ายางรองพื้น", "กันล้มหน้า-หลัง / ตะแกรงหน้า-หลัง / ตะแกรง Topbox", "กระจกบังลม / Topbox / ที่พักเท้า / Accessories Package"]).map(m => (
                                                                <option value={m} key={m}>{m}</option>
                                                            ))}
                                                    </select>
                                                </div>
                                                {!formData?.product && formBlur?.product && (
                                                    <div className={classes.formFieldError}>กรุณาเลือกสินค้าที่ซื้อ</div>
                                                )}
                                            </div>
                                        )}

                                        {~['formType34'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    สินค้าที่ซื้อ
                                                </label>
                                                <div>
                                                    <select
                                                        className={formData.product ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                        name="product"
                                                        value={formData.product}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                    >
                                                        <option value="">เลือกสินค้าที่ซื้อ</option>
                                                        {["Top Box", "กระจกบังลม"].map(m => (
                                                            <option value={m} key={m}>{m}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {!formData?.product && formBlur?.product && (
                                                    <div className={classes.formFieldError}>กรุณาเลือกสินค้าที่ซื้อ</div>
                                                )}
                                            </div>
                                        )}

                                        {~['formType35', 'formType36'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    สินค้าที่ซื้อ
                                                </label>
                                                <div>
                                                    <select
                                                        className={formData.product ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                        name="product"
                                                        value={formData.product}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                    >
                                                        <option value="">เลือกสินค้าที่ซื้อ</option>
                                                        {["Vespa Accessories", "Vespa Licensing", "Bumshaker", ...formType === "formType35" ? ["Profender"] : ["Profender ที่ร่วมรายการ"]].map(m => (
                                                            <option value={m} key={m}>{m}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {!formData?.product && formBlur?.product && (
                                                    <div className={classes.formFieldError}>กรุณาเลือกสินค้าที่ซื้อ</div>
                                                )}
                                            </div>
                                        )}

                                        {~['formType35'].indexOf(formType) < 0 && formData.product && (
                                          <div className={classes.formItem}>
                                              <label className={classes.formLabel}>
                                                  ของแถมที่ได้รับ
                                              </label>
                                              <div className={classes.redeemDetail2}>
                                                  {showVoucher}
                                              </div>
                                          </div>
                                        )}

                                        {/* {~['formType33'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    สี
                                                </label>
                                                <div>
                                                    <select
                                                        className={formData.color ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                        name="color"
                                                        value={formData.color}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                    >

                                                        <option value="">เลือกสี</option>
                                                        {["Chrome", "Nero"].map(m => (
                                                            <option value={m} key={m}>{m}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {!formData?.color && formBlur?.color && (
                                                    <div className={classes.formFieldError}>กรุณาเลือกสี</div>
                                                )}
                                            </div>
                                        )} */}

                                        {~['formType24',
                                            'formType25', 'formType27', 'formType19'
                                            , 'formType28', 'formType30', 'formType32'].indexOf(formType) < 0 && (
                                                <div className={classes.formItem}>
                                                    <label className={classes.formLabel}>
                                                        ขนาดเครื่องยนต์
                                                    </label>
                                                    <div>
                                                        <select
                                                            className={formData.size ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                            name="size"
                                                            value={formData.size}
                                                            onChange={onChangeInput}
                                                            onBlur={onBlur}
                                                        >
                                                            <option value="">เลือกขนาด</option>
                                                            {sizeList.map(m => (
                                                                <option value={m} key={m}>{m}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {!formData?.size && formBlur?.size && (
                                                        <div className={classes.formFieldError}>กรุณาเลือกขนาด</div>
                                                    )}
                                                </div>
                                            )}

                                        {~['formType20', 'formType24', 'formType36'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    มูลค่าสินค้า
                                                </label>
                                                <div>
                                                    <select
                                                        className={formData.usageAmount ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                        name="usageAmount"
                                                        value={formData.usageAmount}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                    >
                                                        <option value="">
                                                            มูลค่าสินค้า
                                                        </option>
                                                        {usegeList.map(m => (
                                                            <option value={m.usageAmount} key={m.key}>{m.usageAmount}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {!formData?.usageAmount && formBlur?.usageAmount && (
                                                    <div className={classes.formFieldError}>กรุณาเลือกมูลค่า</div>
                                                )}
                                            </div>
                                        )}

                                        {~['formType25'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    ตรวจเช็คระยะ
                                                </label>
                                                <div>
                                                    <select
                                                        className={formData.mileageCheckup ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                        name="mileageCheckup"
                                                        value={formData.mileageCheckup}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                    >
                                                        <option value="">
                                                            เลือกระยะ
                                                        </option>
                                                        {mileagList.map(m => (
                                                            <option value={m.value} key={m.key}>{m.label}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {!formData?.mileageCheckup && formBlur?.mileageCheckup && (
                                                    <div className={classes.formFieldError}>กรุณาเลือกระยะ</div>
                                                )}
                                            </div>
                                        )}

                                        {~['formType27', 'formType32'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    รายการอะไหล่
                                                </label>
                                                <div>
                                                    <select
                                                        className={formData.sparepart ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                        name="sparepart"
                                                        value={formData.sparepart}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                    >
                                                        <option value="">
                                                            เลือกรายการอะไหล่
                                                        </option>
                                                        {sparepartList.map(m => (
                                                            <option value={m.value} key={m.key}>{m.value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {!formData?.sparepart && formBlur?.sparepart && (
                                                    <div className={classes.formFieldError}>กรุณาเลือกรายการอะไหล่</div>
                                                )}
                                            </div>
                                        )}

                                        {~['formType32'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    ค่าใช้จ่าย
                                                </label>
                                                <div>
                                                    <select
                                                        className={formData.expenses ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                        name="expenses"
                                                        value={formData.expenses}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                    >
                                                        <option value="">
                                                            เลือกรายการค่าใช้จ่าย
                                                        </option>
                                                        {expenses.map(m => (
                                                            <option value={m.value} key={m.key}>{m.value}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                {!formData?.expenses && formBlur?.expenses && (
                                                    <div className={classes.formFieldError}>กรุณาเลือกรายการค่าใช้จ่าย</div>
                                                )}
                                            </div>
                                        )}

                                        {~['formType20', 'formType36'].indexOf(formType) < 0 && formData.usageAmount && formData.product && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    คูปองที่ได้รับ
                                                </label>
                                                <div className={classes.redeemDetail2}>
                                                    &bull; {formData?.couponValue}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType32'].indexOf(formType) < 0 && formData.model && formData.size && formData.expenses && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    คูปองที่ได้รับ
                                                </label>
                                                <div className={classes.redeemDetail2}>
                                                    &bull; {formData?.couponValue}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType24', 'formType25', 'formType27', 'formType28'].indexOf(formType) < 0 && formData.model && formData.size && formData.sparepart && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    คูปองที่ได้รับ
                                                </label>
                                                <div className={classes.redeemDetail2}>
                                                    &bull; {formData?.couponValue}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType5'].indexOf(formType) < 0 && formData.model && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    ของแถมเพิ่มเติม
                                                </label>
                                                <div className={classes.redeemDetail2}>
                                                    {/* &bull; Voucher 15,000 บาท<br />
                                            &bull; Vespa Voyager Pack<br />(กระเป๋าเป้สะพายหลัง, หมวก และผ้าเช็ดหน้า)<br />
                                            &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์<br />
                                            &bull; Vespa Welcome Kit<br />
                                            &bull; ประกันภัยชั้น 1 (1 ปี) */}
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType25'].indexOf(formType) < 0 && formData.mileageCheckup && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    ของแถมเพิ่มเติม
                                                </label>
                                                <div className={classes.redeemDetail2}>
                                                    &bull; ฟรีบริการตรวจสภาพรถ 10 รายการ
                                                </div>
                                            </div>
                                        )}

                                        {~['formType26'].indexOf(formType) < 0 && formData.model && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    ของแถมเพิ่มเติม
                                                </label>
                                                <div className={classes.redeemDetail2}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType29', 'formType31', 'formType33'].indexOf(formType) < 0 && formData.model && formData.product && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    ของแถมที่ได้รับ
                                                </label>
                                                <div className={classes.redeemDetail2}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {formType === 'formType10' && formData.product && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    คูปองที่ได้
                                                </label>
                                                <div className={classes.redeemDetail2}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType30'].indexOf(formType) < 0 && formData.size && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    รายการอะไหล่
                                                </label>
                                                <div className={classes.redeemDetail2}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {/* {~['formType30'].indexOf(formType) < 0 && (
                                    <div className={classes.formItem}>
                                        <label className={classes.formLabel}>
                                            รวมค่าใช้จ่าย
                                        </label>
                                        <div>
                                            <input className={classes.formFieldInput} 
                                                placeholder=""
                                                type="text"
                                                name="couponValue"
                                                value={formData?.couponValue || ''}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                )} */}

                                        {~['formType5', 'formType1',
                                            'formType21', 'formType23', 'formType24',
                                            'formType25', 'formType27',
                                            'formType19', 'formType28', 'formType29', 'formType30', 'formType32', 'formType31'
                                        ].indexOf(formType) < 0 && (
                                                <div className={classes.formItem}>
                                                    <label className={classes.formLabel}>
                                                        เลขเครื่องยนต์
                                                    </label>
                                                    <div>
                                                        <input className={classes.formFieldInput}
                                                            placeholder="เลขเครื่องยนต์"
                                                            type="text"
                                                            name="engineNumber"
                                                            value={formData?.engineNumber}
                                                            onChange={onChangeInput}
                                                            onBlur={onBlur}
                                                            maxLength={12}
                                                        />
                                                    </div>
                                                    {!formData?.engineNumber && formBlur?.engineNumber && (
                                                        <div className={classes.formFieldError}>กรุณากรอกเลขเครื่องยนต์</div>
                                                    )}
                                                    {formData?.engineNumber && (
                                                        <>
                                                            {!(/^[a-zA-Z0-9]+$/.test(formData?.engineNumber)) ? (
                                                                <div className={classes.formFieldError}>เลขเครื่องยนต์ไม่ถูกต้อง</div>
                                                            ) : formData?.engineNumber.length < 12 ? (
                                                                <div className={classes.formFieldError}>ความยาว 12 หลัก</div>
                                                            ) : !checkEngineNumberFormType29 ? (
                                                                <div className={classes.formFieldError}>{errorEngineNumber}</div>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </div>
                                            )}

                                        {~['formType10', 'formType24', 'formType25', 'formType26', 'formType27', 'formType28', 'formType30', 'formType31', 'formType32', 'formType37', 'formType38'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    เลขถังรถ
                                                </label>
                                                <div>
                                                    <input className={classes.formFieldInput}
                                                        placeholder="เลขถังรถ"
                                                        type="text"
                                                        name="bodyNumber"
                                                        value={formData?.bodyNumber}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                        maxLength={17}
                                                    />
                                                </div>
                                                {!formData?.bodyNumber && formBlur?.bodyNumber && (
                                                    <div className={classes.formFieldError}>กรุณากรอกเลขถังรถ</div>
                                                )}
                                                {formData?.bodyNumber && (
                                                    <>
                                                        {!(/^[A-Za-z]{2}[0-9]/.test(formData.bodyNumber)) ? (
                                                            <div className={classes.formFieldError}>ข้อมูลที่ป้อนต้องเริ่มต้นด้วยตัวอักษรสองตัวและตามด้วยตัวเลขหนึ่งตัว</div>
                                                        ) : (
                                                            <>
                                                                {!(/^[a-zA-Z0-9]+$/.test(formData?.bodyNumber)) ? (
                                                                    <div className={classes.formFieldError}>เลขถังรถไม่ถูกต้อง</div>
                                                                ) : formData?.bodyNumber.length < 17 ? (
                                                                    <div className={classes.formFieldError}>ความยาว 17 หลัก</div>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )}

                                        {~['formType1', 'formType3'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    ที่อยู่
                                                </label>
                                                <div>
                                                    <textarea
                                                        className={classes.formFieldTextArea}
                                                        placeholder="ที่อยู่"
                                                        type="text"
                                                        name="addressDetail"
                                                        value={formData?.addressDetail}
                                                        onChange={onChangeInput}
                                                        onBlur={onBlur}
                                                        rows={4}
                                                    />
                                                </div>
                                                {!formData?.addressDetail && formBlur?.addressDetail && (
                                                    <div className={classes.formFieldError}>กรุณากรอกที่อยู่</div>
                                                )}
                                            </div>
                                        )}

                                        {~['formType21', 'formType23',
                                            'formType19', 'formType29'
                                        ].indexOf(formType) < 0 && (
                                                <div className={classes.formItem}>
                                                    <label className={classes.formLabel}>
                                                        วันที่ออกรถ
                                                    </label>
                                                    <div style={{ width: '100%', display: 'flex' }}>
                                                        <div className={classes.formBirthDayDate}>
                                                            <select
                                                                className={formData.purchasedDate ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                                name="purchasedDate"
                                                                value={formData.purchasedDate}
                                                                onChange={onChangeInput}
                                                                onBlur={onBlur}
                                                            >
                                                                <option value="">วันที่</option>
                                                                {dateList.map(m => (
                                                                    <option value={m} key={m}>{m}</option>
                                                                ))}
                                                            </select>
                                                            {!formData.purchasedDate && formBlur.purchasedDate && (
                                                                <div className={classes.formFieldError}>
                                                                    กรุณาระบุวันที่
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className={classes.formBirthDayMonth}>
                                                            <select
                                                                className={formData.purchasedMonth ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                                name="purchasedMonth"
                                                                value={formData.purchasedMonth}
                                                                onChange={onChangeInput}
                                                                onBlur={onBlur}
                                                            >
                                                                <option value="">เดือน</option>
                                                                {monthList.map((m, i) => (
                                                                    <option value={m.key} key={m.key}>{m.th}</option>
                                                                ))}
                                                            </select>
                                                            {!formData.purchasedMonth && formBlur.purchasedMonth && (
                                                                <div className={classes.formFieldError}>
                                                                    กรุณาระบุเดือน
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className={classes.formBirthDayYear}>
                                                            <select
                                                                className={formData.purchasedYear ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                                name="purchasedYear"
                                                                value={formData.purchasedYear}
                                                                onChange={onChangeInput}
                                                                onBlur={onBlur}
                                                            >
                                                                <option value="">ปี</option>
                                                                {yearList.map(m => (
                                                                    <option value={m.en} key={m.en}>{m.th}</option>
                                                                ))}
                                                            </select>
                                                            {!formData.purchasedYear && formBlur.purchasedYear && (
                                                                <div className={classes.formFieldError}>
                                                                    กรุณาระบุปี
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {notValidPurchaseDate && (
                                                        <div className={classes.formFieldError}>
                                                            วันที่ออกรถไม่ถูกต้อง
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                        {~['formType5', 'formType24', 'formType25', 'formType27', 'formType28', 'formType30', 'formType32', 'formType20', 'formType26', 'formType31', 'formType33', 'formType10', 'formType34', 'formType35', 'formType36', 'formType37', 'formType38'].indexOf(formType) < 0 && (
                                            <div className={classes.formItem}>
                                                <label className={classes.formLabel}>
                                                    วันที่ใช้บริการ
                                                </label>
                                                <div style={{ width: '100%', display: 'flex' }}>
                                                    <div className={classes.formBirthDayDate}>
                                                        <select
                                                            className={formData.serviceDate ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                            name="serviceDate"
                                                            value={formData.serviceDate}
                                                            onChange={onChangeInput}
                                                            onBlur={onBlur}
                                                        >
                                                            <option value="">วันที่</option>
                                                            {dateList.map(m => (
                                                                <option value={m} key={m}>{m}</option>
                                                            ))}
                                                        </select>
                                                        {!formData.serviceDate && formBlur.serviceDate && (
                                                            <div className={classes.formFieldError}>
                                                                กรุณาระบุวันที่
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className={classes.formBirthDayMonth}>
                                                        <select
                                                            className={formData.serviceMonth ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                            name="serviceMonth"
                                                            value={formData.serviceMonth}
                                                            onChange={onChangeInput}
                                                            onBlur={onBlur}
                                                        >
                                                            <option value="">เดือน</option>
                                                            {monthList.map((m, i) => (
                                                                <option value={m.key} key={m.key}>{m.th}</option>
                                                            ))}
                                                        </select>
                                                        {!formData.serviceMonth && formBlur.serviceMonth && (
                                                            <div className={classes.formFieldError}>
                                                                กรุณาระบุเดือน
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className={classes.formBirthDayYear}>
                                                        <select
                                                            className={formData.serviceYear ? classes.formFieldInput : clsx(classes.formFieldInput, classes.formSelectEmpty)}
                                                            name="serviceYear"
                                                            value={formData.serviceYear}
                                                            onChange={onChangeInput}
                                                            onBlur={onBlur}
                                                        >
                                                            <option value="">ปี</option>
                                                            {yearList.map(m => (
                                                                <option value={m.en} key={m.en}>{m.th}</option>
                                                            ))}
                                                        </select>
                                                        {!formData.serviceYear && formBlur.serviceYear && (
                                                            <div className={classes.formFieldError}>
                                                                กรุณาระบุปี
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                {notValidServiceDate && (
                                                    <div className={classes.formFieldError}>
                                                        วันที่ใช้บริการไม่ถูกต้อง
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </form>

                                    <div className={classes.remark}>
                                        <div className={classes.remarkHeading}>
                                            เงื่อนไขการใช้คูปอง
                                        </div>
                                        <ul className={classes.remarkGroup}>
                                            {termList.map((m, i) => (
                                                <li className={classes.remarkList}>
                                                    <span key={i}>{m}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showForm && (
                            <div className={classes.coupon}>
                                <div className={classes.coupons}>
                                    <div className={classes.couponImageWrap}>
                                        <img
                                            className="coupon__image"
                                            src={image || data?.assetrectangle}
                                        />

                                        <div className={classes.couponNumberWrap}>
                                            <div className={classes.couponNumber}>
                                                {`No. ${data?.code}`}
                                            </div>
                                        </div>
                                        <div className={classes.couponFooter}>
                                            <div className={classes.couponContentFooter}> {`หมดเขต ${humanExpireTime(data.expiretime)}`}</div>
                                        </div>
                                    </div>

                                    <div className={classes.redeem}>
                                        <div className={classes.redeemBox}>
                                            <div className={classes.redeemHeader}>
                                                ชื่อ-นามสกุล
                                            </div>
                                            <div className={classes.redeemDetail}>
                                                {formData?.firstname} {formData?.lastname}
                                            </div>
                                        </div>

                                        <div className={classes.redeemBox}>
                                            <div className={classes.redeemHeader}>
                                                เบอร์โทรศัพท์
                                            </div>
                                            <div className={classes.redeemDetail}>
                                                {formData?.mobile}
                                            </div>
                                        </div>

                                        {~['formType5', 'formType20', 'formType21',
                                            'formType23', 'formType24', 'formType25',
                                            'formType26', 'formType27', 'formType10', 'formType19'
                                            , 'formType28', 'formType29', 'formType30', 'formType31', 'formType32', 'formType33', 'formType34', 'formType35', 'formType36', 'formType37', 'formType38'].indexOf(formType) < 0 && (
                                                <div className={classes.redeemBox}>
                                                    <div className={classes.redeemHeader}>
                                                        โชว์รูม/ศูนย์บริการ
                                                    </div>
                                                    <div className={classes.redeemDetail}>
                                                        {formData?.targetBranch}
                                                    </div>
                                                </div>
                                            )}

                                        {~['formType35'].indexOf(formType) < 0 && (
                                          <div className={classes.redeemBox}>
                                              <div className={classes.redeemHeader}>
                                                  เลขถังรถ
                                              </div>
                                              <div className={classes.redeemDetail}>
                                                  {formData?.bodyNumber}
                                              </div>
                                          </div>
                                        )}



                                        {~['formType37'].indexOf(formType) < 0 && (
                                          <div className={classes.redeemBox}>
                                              <div className={classes.redeemHeader}>
                                                  แพ็กเกจที่ซื้อ
                                              </div>
                                              <div className={classes.redeemDetail}>
                                                  {formData?.package}
                                              </div>
                                          </div>
                                        )}


                                        {~['formType5', 'formType10', 'formType20', 'formType21',
                                            'formType23', 'formType24', 'formType25',
                                            'formType26', 'formType27', 'formType19'
                                            , 'formType28', 'formType29', 'formType30', 'formType31', 'formType32', 'formType33', 'formType34', 'formType37', 'formType37', 'formType38'].indexOf(formType) < 0 && (
                                                <div className={classes.redeemBox}>
                                                    <div className={classes.redeemHeader}>
                                                        รุ่นรถ
                                                    </div>
                                                    <div className={classes.redeemDetail}>
                                                        {formData?.model}
                                                    </div>
                                                </div>
                                            )}

                                        {~['formType34'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <div className={classes.redeemHeader}>
                                                    คูปองที่ได้รับ
                                                </div>
                                                <div className={classes.redeemDetail}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType37', 'formType38'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <div className={classes.redeemHeader}>
                                                    คูปองที่ได้รับ
                                                </div>
                                                <div className={classes.redeemDetail}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType24', 'formType25', 'formType27',
                                            'formType19', 'formType28', 'formType30', 'formType32'].indexOf(formType) < 0 && (
                                                <div className={classes.redeemBox}>
                                                    <div className={classes.redeemHeader}>
                                                        ขนาดเครื่องยนต์
                                                    </div>
                                                    <div className={classes.redeemDetail}>
                                                        {formData?.size}
                                                    </div>
                                                </div>
                                            )}

                                        {~['formType31', 'formType33', 'formType10', 'formType34', 'formType35', 'formType36'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <div className={classes.redeemHeader}>
                                                    สินค้าที่ซื้อ
                                                </div>
                                                <div className={classes.redeemDetail}>
                                                    {formData?.product}
                                                </div>
                                            </div>
                                        )}

                                        {/* {~['formType33'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <div className={classes.redeemHeader}>
                                                    สี
                                                </div>
                                                <div className={classes.redeemDetail}>
                                                    {formData?.color}
                                                </div>
                                            </div>
                                        )} */}

                                        {~['formType20', 'formType24', 'formType36'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <div className={classes.redeemHeader}>
                                                    มูลค่าสินค้า
                                                </div>
                                                <div className={classes.redeemDetail}>
                                                    {formData?.usageAmount}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType25'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <div className={classes.redeemHeader}>
                                                    ตรวจเช็คระยะ
                                                </div>
                                                <div className={classes.redeemDetail}>
                                                    {formData?.mileageCheckup}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType27', 'formType32'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <div className={classes.redeemHeader}>
                                                    รายการอะไหล่
                                                </div>
                                                <div className={classes.redeemDetail}>
                                                    {formData?.sparepart}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType24', 'formType25', 'formType27'
                                            , 'formType28', 'formType32'].indexOf(formType) < 0 && (
                                                <div className={classes.redeemBox}>
                                                    <div className={classes.redeemHeader}>
                                                        {/* มูลค่าคูปอง */}
                                                        คูปองที่ได้รับ
                                                    </div>
                                                    <div className={classes.redeemDetail}>
                                                        &bull; {formData?.couponValue}
                                                    </div>
                                                </div>
                                            )}

                                        {~['formType5'].indexOf(formType) < 0 && formData.model && (
                                            <div className={classes.redeemBox}>
                                                <label className={classes.redeemHeader}>
                                                    ของแถมเพิ่มเติม
                                                </label>
                                                <div className={classes.redeemDetail}>
                                                    {/* &bull; Voucher 15,000 บาท<br />
                                            &bull; Vespa Voyager Pack<br />
                                            &bull; New Normal Pack หน้ากากผ้าและแอลกอฮอล์สเปรย์<br />
                                            &bull; Vespa Welcome Kit<br />
                                            &bull; ประกันภัยชั้น 1 (1 ปี) */}
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType29'].indexOf(formType) < 0 && formData.model && (
                                            <div className={classes.redeemBox}>
                                                <label className={classes.redeemHeader}>
                                                    ของแถมที่ได้รับ
                                                </label>
                                                <div className={classes.redeemDetail}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType25'].indexOf(formType) < 0 && formData.mileageCheckup && (
                                            <div className={classes.redeemBox}>
                                                <label className={classes.redeemHeader}>
                                                    ของแถมเพิ่มเติม
                                                </label>
                                                <div className={classes.redeemDetail}>
                                                    &bull; ฟรีบริการตรวจสภาพรถ 10 รายการ
                                                </div>
                                            </div>
                                        )}

                                        {~['formType26'].indexOf(formType) < 0 && formData.model && (
                                            <div className={classes.redeemBox}>
                                                <label className={classes.redeemHeader}>
                                                    ของแถมเพิ่มเติม
                                                </label>
                                                <div className={classes.redeemDetail}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType31', 'formType33'].indexOf(formType) < 0 && formData.model && (
                                            <div className={classes.redeemBox}>
                                                <label className={classes.redeemHeader}>
                                                    คูปองที่ได้
                                                </label>
                                                <div className={classes.redeemDetail}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType35'].indexOf(formType) < 0 && formData.product && (
                                            <div className={classes.redeemBox}>
                                                <label className={classes.redeemHeader}>
                                                    คูปองที่ได้
                                                </label>
                                                <div className={classes.redeemDetail}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {formType === 'formType10' && (
                                            <div className={classes.redeemBox}>
                                                <label className={classes.redeemHeader}>
                                                    คูปองที่ได้
                                                </label>
                                                <div className={classes.redeemDetail}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType30'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <label className={classes.redeemHeader}>
                                                    รายการอะไหล่
                                                </label>
                                                <div className={classes.redeemDetail}>
                                                    {showVoucher}
                                                </div>
                                            </div>
                                        )}

                                        {/* {~['formType30'].indexOf(formType) < 0 && (
                                    <div className={classes.redeemBox}>
                                        <div className={classes.redeemHeader}>
                                            รวมค่าใช้จ่าย
                                        </div>
                                        <div className={classes.redeemDetail}>
                                            {formData?.couponValue}
                                        </div>
                                    </div>
                                )} */}

                                        {~['formType5', 'formType1',
                                            'formType21', 'formType23', 'formType24',
                                            'formType25', 'formType27',
                                            'formType19', 'formType28'
                                            , 'formType29', 'formType30', 'formType32', "formType31"
                                        ].indexOf(formType) < 0 && (
                                                <div className={classes.redeemBox}>
                                                    <div className={classes.redeemHeader}>
                                                        เลขเครื่องยนต์
                                                    </div>
                                                    <div className={classes.redeemDetail}>
                                                        {formData?.engineNumber}
                                                    </div>
                                                </div>
                                            )}

                                        {~['formType10', 'formType24', 'formType25', 'formType26', 'formType27', 'formType28', 'formType30', 'formType31', 'formType32', 'formType37', 'formType38'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <div className={classes.redeemHeader}>
                                                    เลขถังรถ
                                                </div>
                                                <div className={classes.redeemDetail}>
                                                    {formData?.bodyNumber}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType21', 'formType23',
                                            'formType19', 'formType29'
                                        ].indexOf(formType) < 0 && (
                                                <div className={classes.redeemBox}>
                                                    <div className={classes.redeemHeader}>
                                                        วันที่ออกรถ
                                                    </div>
                                                    <div className={classes.redeemDetail}>
                                                        {formData?.purchasedDate} {availableMonths[parseInt(formData?.purchasedMonth) - 1]?.th} {parseInt(formData?.purchasedYear || 0) + 543}
                                                    </div>
                                                </div>
                                            )}

                                        {~['formType5', 'formType10', 'formType24', 'formType25', 'formType27', 'formType28', 'formType30', 'formType32', 'formType20', 'formType26', 'formType31', 'formType33', 'formType34', 'formType35', 'formType36', 'formType37', 'formType38'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <div className={classes.redeemHeader}>
                                                    วันที่ใช้บริการ
                                                </div>
                                                <div className={classes.redeemDetail}>
                                                    {formData?.serviceDate} {availableMonths[parseInt(formData?.serviceMonth) - 1]?.th} {parseInt(formData?.serviceYear || 0) + 543}
                                                </div>
                                            </div>
                                        )}

                                        {~['formType1', 'formType3'].indexOf(formType) < 0 && (
                                            <div className={classes.redeemBox}>
                                                <div className={classes.redeemHeader}>
                                                    ที่อยู่
                                                </div>
                                                <div className={classes.redeemDetail}>
                                                    {formData?.addressDetail}
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className={classes.confirmRemark}>
                                        <p className={classes.confirmParagraph}>
                                            {`หากข้อมูลถูกต้อง กรุณากด `}
                                            <span className={classes.confirmHilight}>'ยืนยันข้อมูล'</span>
                                            {` อีกครั้ง เพื่อบันทึกข้อมูลการใช้
                                    และเข้าไปที่หน้า คูปองที่ใช้แล้ว/หมดอายุ
                                    เพื่อแคปหน้าจอเป็นหลักฐาน`}
                                        </p>
                                        <p className={classes.confirmParagraph}>
                                            {`หากไม่กด `}
                                            <span className={classes.confirmUnderLine}>'ยืนยันข้อมูล'</span>
                                            {` จะถือว่าการใช้ไม่สมบูรณ์
                                    และสิทธิ์จะเป็นโมฆะทันที`}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}

                        {showForm ? (
                            <div className={classes.action2}>
                                <button
                                    className={classes.actionButton}
                                    onClick={backToEdit}
                                    style={{ display: 'inline-block' }}
                                >แก้ไขข้อมูล</button>
                                <button
                                    className={clsx(classes.actionButton, classes.actionSubmit)}
                                    onClick={onSubmit}
                                    style={{ display: 'inline-block' }}
                                >ยืนยันข้อมูล</button>
                            </div>
                        ) :
                            <div className={classes.action}>
                                <button
                                    className={isSubmit ? classes.actionRedeem : clsx(classes.actionRedeem, classes.actionRedeemDisabled)}
                                    onClick={usedCoupon}
                                    disabled={!isSubmit}
                                >กดใช้คูปอง</button>
                            </div>
                        }
                    </div>
                </div>

                {loading && <Loading />}
            </div>
        }
    </>)
}

export default MyNewCouponDetail;